import React, {useEffect, useState} from 'react';
import {
  Box, Card, CardContent,
  Container,
  Grid, InputAdornment,
  makeStyles, SvgIcon, TextField, Typography
} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ProductCard from './ProductCard';
import ProtectedPage from "../../../components/ProtectedPage";
import {connect} from "react-redux";
import {deleteAddressAction, fetchAddressesAction} from "../../../redux/actions/addressActions";
import {Search as SearchIcon} from "react-feather";
import Button from "@material-ui/core/Button";
import {useNavigate} from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark, // for gradient
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  paper: {
    position: 'absolute',
    maxWidth: "500px",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  buttonDanger: {
    backgroundColor: theme.palette.danger.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.danger.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.danger.light
    }
  },
}));

const ProductList = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {loadingState, addressState, fetchAddressesActionDispatch, deleteAddressDispatch} = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchKeywords, setCurrentSearchKeywords] = useState("");

  const [idToDelete, setIdToDelete] = useState(null);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const handleChangeKeywords = (event) => {
    setCurrentSearchKeywords(event.target.value);
  }


  useEffect(() => {
    // Initialize addresses
    if (!addressState.firstLoading) {
      fetchAddressesActionDispatch();
    }
  }, [])

  useEffect(() => {
    fetchAddressesActionDispatch(currentPage, currentSearchKeywords,);
  }, [currentPage, currentSearchKeywords])

  if (loadingState.page_loading) {
    return <Grid
      justify="center">
      <CircularProgress color="primary"/>
    </Grid>
  }


  return (
    <ProtectedPage
      className={classes.root}
      title="My addresses"
    >

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Delete this address"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >

        <Grid className={classes.paper} container direction="column" alignItems="center">
          <Grid item>
            <HighlightOffIcon color="error" style={{width: 85, height: 85,}}/>
          </Grid>
          <Grid item>
            <Box mt={2}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                Are you sure ?
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box mt={2} mb={2} style={{textAlign: "center",}}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="subtitle1"
              >
                Do you really want to delete this address?
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="subtitle1"
              >
                This process cannot be undone.
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Grid container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center">
              <Grid item xs={6}>

                <Box mr={1}>
                  <Button variant="contained" className={classes.buttonDanger} onClick={() => {
                    deleteAddressDispatch(idToDelete, () => {
                      setOpen(false);
                    })
                  }}>
                    Delete
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Button variant="contained" color="default" onClick={() => {
                  setOpen(false);
                }}>
                  Cancel
                </Button>
              </Grid>


            </Grid>
          </Grid>

        </Grid>

      </Modal>


      <Container maxWidth={false}
                 direction="row"
                 justify="flex-start">
        <Grid container justify="flex-end">
          <Box mb={2}>
            <Button variant="contained" color="primary" onClick={() => {
              navigate("/add/address");
            }}>
              Add a new address
            </Button>
          </Box>
        </Grid>

        <Card>

          <CardContent>

            <Grid container>
              <Box mb={1}>
                <Typography
                  className={classes.name}
                  color="textPrimary"
                  variant="h5"
                >
                  Filters:
                </Typography>
              </Box>
            </Grid>

            <Grid container>
              <Box item mr={2} mt={2}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon/>
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Filter addresses"
                  variant="outlined"
                  onChange={handleChangeKeywords}
                />
              </Box>



            </Grid>
          </CardContent>
        </Card>


        <Box mt={3}>
          {
            !addressState.results.length ? (
              <Grid container
                    direction="column"
                    alignItems="center"
                    justify="center">
                <Grid item>
                  <Box>
                    <img
                      className={classes.logo}
                      alt="Logo"
                      src="/static/guys.svg"

                    />
                  </Box>

                </Grid>

                <Grid item>
                  <Box mt={1}>
                    <Button variant="contained" color="primary" onClick={() => {
                      navigate("/add/address");
                    }}>
                      Add a new address
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            ) : (
              <React.Fragment>
                <Grid
                  container
                  spacing={3}
                >
                  {addressState.results.map((address) => (
                    <Grid
                      item
                      key={address.id}
                      lg={4}
                      md={6}
                      xs={12}
                    >
                      <ProductCard
                        className={classes.productCard}
                        product={address}
                        onDeleteClick={() => {
                          setIdToDelete(address.id);
                          handleOpen();
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>

                <Box
                  mt={3}
                  display="flex"
                  justifyContent="center"
                >
                  <Pagination
                    color="primary"
                    count={addressState.lastPage}
                    size="small"
                    page={addressState.current} onChange={handleChangePage}
                  />
                </Box>
              </React.Fragment>
            )
          }

        </Box>

      </Container>
    </ProtectedPage>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingState: state.loadingReducer,
    addressState: state.addressReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAddressesActionDispatch: (pageNumber, searchKeywords) => {
      dispatch(fetchAddressesAction(pageNumber, searchKeywords))
    },
    deleteAddressDispatch: (id, success_cb) => {
      dispatch(deleteAddressAction(id, success_cb))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
