const findCategoryRecursively = (categoryId, categoriesArray) => {

  let category;
  categoriesArray.some(c => c.id === categoryId && (category = c) || (category = findCategoryRecursively(categoryId, c.children || [])));
  return category;

};

const findCategoryParentRecursively = (categoryId, categoriesArray) => {

  let parent_category = null;

  for (let i = 0; i < categoriesArray.length; i++) {

    if (categoriesArray[i].children.some(c => c.id === categoryId)) {
      parent_category = categoriesArray[i]
      break;
    } else {
      parent_category = findCategoryParentRecursively(categoryId, categoriesArray[i].children)

      if (parent_category !== null) {
        break;
      }
    }
  }
  return parent_category;
};


export {
  findCategoryRecursively,
  findCategoryParentRecursively
}
