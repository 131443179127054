import React, { useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik, replace} from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';

import {FACEBOOK_APP_ID} from 'src/utils/globals'
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import UnprotectedPage from 'src/components/UnprotectedPage';

import Alert from "@material-ui/lab/Alert";
import {connect} from "react-redux";
import {facebookLoginAction, googleLoginAction, userLoginAction} from "../../redux/actions/userActions";
import {GOOGLE_OAUTH_CLIENT_KEY} from "../../utils/globals";


const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark, // for gradient
    background: "linear-gradient(135deg, rgba(221,251,251,1) 0%, rgba(250,237,234,1) 100%)",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    minHeight: "100%",
    [theme.breakpoints.up('sm')]: {
      height: "100%",
    },
  },
  inputBgColor:{
      backgroundColor:"white",
  },

  pageHeight: {
    //backgroundColor: "#f4f6f8",
    [theme.breakpoints.up('sm')]: {
      height: "100%",
    },
  },
}));

const LoginView = (props) => {
  const classes = useStyles();

  const {loadingState, googleLoginDispatch, facebookLoginDispatch} = props;

  const [nonFieldError, setNonFieldError] = useState("");


  const responseFacebook = (resp) => {
    facebookLoginDispatch(resp.accessToken)
  }

  const responseGoogle = (resp) => {
    googleLoginDispatch(resp.accessToken);
  }


  return (
    <UnprotectedPage
      className={classes.root}
      title="Login"
    >
      <Box
        className={classes.pageHeight}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >

        <Container maxWidth="sm">

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(data, actions) => {
              props.userLoginDispatch(data, actions, setNonFieldError);
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3} display="flex" justifyContent="center">
                  <img
                    alt="Logo"
                    src="/static/logo-horizontal.svg"
                    {...props}
                  />
                </Box>

                <Box mb={0} display="flex" justifyContent="center">


                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box>

                <Box mb={3} display="flex" justifyContent="center">

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>

                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <FacebookLogin
                      appId={FACEBOOK_APP_ID}
                      autoLoad={false}
                      callback={responseFacebook}
                      render={renderProps => (
                        <Button
                          color="primary"
                          fullWidth
                          startIcon={<FacebookIcon/>}
                          size="large"
                          variant="contained"
                          onClick={renderProps.onClick}
                        >
                          Login with Facebook
                        </Button>
                      )}
                    />


                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <GoogleLogin
                      clientId={GOOGLE_OAUTH_CLIENT_KEY}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={'single_host_origin'}
                      render={renderProps => (
                        <Button
                          style={{backgroundColor:"#ED3739",color:"white",}}
                          fullWidth
                          startIcon={<GoogleIcon/>}
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          size="large"
                          variant="contained"
                        >

                          Login with Google
                        </Button>
                      )}
                    />

                  </Grid>
                </Grid>

                <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    or login with email address
                  </Typography>
                </Box>

                {nonFieldError !== "" ? (
                  <Alert severity="error">{nonFieldError}</Alert>
                ) : ""}

                <TextField
                  InputProps={{
                    className: classes.inputBgColor,
                  }}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    className: classes.inputBgColor,
                  }}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                  {' or '}
                  <Link
                    component={RouterLink}
                    to="/register/business/"
                    variant="h6"
                  >
                    Sign up for businesses
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  <Link
                    component={RouterLink}
                    to="/forgot-password/"
                    variant="h6"
                  >
                    Forgot your password?
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </UnprotectedPage>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state,
    loadingState: state.loadingReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLoginDispatch: (data, actions, setNonFieldError) => {
      dispatch(userLoginAction(data, actions, setNonFieldError))
    },
    facebookLoginDispatch: (access_token) => {
      dispatch(facebookLoginAction(access_token))
    },
    googleLoginDispatch: (access_token) => {
      dispatch(googleLoginAction(access_token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
