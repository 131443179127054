import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import ProtectedPage from 'src/components/ProtectedPage';
import {useParams} from "react-router";
import AddressUpdateForm from "./AddressUpdateForm";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark, // for gradient
    minHeight: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  }
}));

const AddressUpdateView = () => {
  let {id} = useParams();

  const classes = useStyles();

  return (
    <ProtectedPage
      className={classes.root}
      title="Add an address"
    >
      <AddressUpdateForm id={id} />
    </ProtectedPage>
  );
};

export default AddressUpdateView;
