import React, {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import UnprotectedPage from "../../components/UnprotectedPage";
import axiosInterceptor from "../../utils/axiosInterceptor";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgotPasswordView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [nonFieldError, setNonFieldError] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);



  return (
    <UnprotectedPage
      className={classes.root}
      title="Forgot password request"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {showSuccessMsg ? (
          <Container maxWidth="sm">
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Forgot password request
              </Typography>

            </Box>

            <Alert severity="success">
              <AlertTitle>We've emailed you instructions for setting your password</AlertTitle>
              <p>
                If an account exists with the email you entered.
                You should receive them shortly.
              </p>
              <p>
                If you don't receive an email, please make sure you've entered the address you registered with,
                and check your spam folder.
              </p>
            </Alert>
          </Container>
          ) :
          <Container maxWidth="sm">

            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              })}
              onSubmit={(data, actions) => {

                const apiUrl = 'users/registration/password/reset/';

                setNonFieldError("");
                setShowSuccessMsg(false);

                axiosInterceptor.post(apiUrl, data)
                  .then(response => {
                    setShowSuccessMsg(true);
                  })
                  .catch(error => {
                    let errors = error.response.data;

                    actions.setErrors(errors);

                    if (errors.non_field_errors) {
                      setNonFieldError(errors.non_field_errors);
                    }

                  }).finally(() => {
                  actions.setSubmitting(false);
                });

              }}
            >
              {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Forgot password request
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Please enter your email address
                    </Typography>
                  </Box>

                  {nonFieldError !== "" ? (
                    <Alert severity="error">{nonFieldError}</Alert>
                  ) : ""}

                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Request password reset
                    </Button>
                  </Box>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Don&apos;t have an account?
                    {' '}
                    <Link
                      component={RouterLink}
                      to="/register"
                      variant="h6"
                    >
                      Sign up
                    </Link>
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Remember your password? {' '}
                    <Link
                      component={RouterLink}
                      to="/login"
                      variant="h6"
                    >
                      Sign in
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          </Container>
        }

      </Box>
    </UnprotectedPage>
  );
};

export default ForgotPasswordView;
