import React from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Password from './Password';
import ProtectedPage from "../../../components/ProtectedPage";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark, // for gradient
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();

  return (
    <ProtectedPage
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Box >
          <Password />
        </Box>
      </Container>
    </ProtectedPage>
  );
};

export default SettingsView;
