import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardContent,
  Grid,
  TextField,
  makeStyles, Typography
} from '@material-ui/core';
import {Formik} from 'formik';
import {connect} from "react-redux";
import * as Yup from "yup";
import MapContainer from "../../../components/MapContainer";
import {postAddressAction} from "../../../redux/actions/addressActions";
import Alert from "@material-ui/lab/Alert";
import LocationSearchInputComponent from "../../../components/LocationSearchInputComponent";
import MyLocationTwoToneIcon from "@material-ui/icons/MyLocationTwoTone";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useNavigate} from "react-router";
import {apiGenerateDigitalAddress} from "../../../redux/api/addresses";
import AdasLoading from "../../../components/AdasLoading";
import AddressCategoryChooserInput from "../../../components/categories/AddressCategoryChooserInput";


const useStyles = makeStyles((theme) => ({
  root: {},
  FormError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  },
  buttonTertiary: {
    backgroundColor: theme.palette.tertiary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.tertiary.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.tertiary.light
    }
  },

  greenBtn: {
    backgroundColor: theme.palette.green.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.green.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.green.light
    }
  },

  addressBox: {
    padding: "18.5px 14px",
    border: "1px solid #0000003b",
    borderRadius: "4px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  addressBoxTitle: {
    color: "#939393",
  },
  addressBoxContent: {
    fontSize: 15,
    color: "#001260",
  },
  digitalAddressBoxContent: {
    fontSize: 40,
    fontWeight: "bolder",
  },
  pageTitle: {
    fontWeight: "bolder",
  },

  searchGrid: {
    backgroundColor: "white",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(7),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    order: 2,
    [theme.breakpoints.up('md')]: {
      //height: 'calc(100vh - 64px)',
      height: 'calc(100vh)',
      order: 1,
    },

  },
  searchGridItem: {
    padding: "0 !important",

  },
  mapGrid: {
    order: 1,
    minHeight: "300px",
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginTop: "0",
    },
  },


  searchCurrentSuggestionPrimary1: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  searchCurrentSuggestionSecondary1: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: theme.palette.secondary.main
  },
  searchCurrentSuggestionPrimary2: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  searchCurrentSuggestionSecondary2: {
    fontSize: "0.9rem",
  }

}));

const ProductCreateForm = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const {loadingState, dispatchPostAddress, authState} = props;

  const formikRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);


  const [currentMapPosition, setCurrentMapPosition] = useState({
    lat: 0,
    lng: 0
  });

  const [currentMapZoom, setCurrentMapZoom] = useState(1);

  const [address, setAddress] = useState({
    id: 0,
    address: "---",
    title: "",
    category:null,
    description: "",
    digital_address: "---",
    plus_code: "",
    country_name: "",
    country_code: "",
    geo_lat: "---",
    geo_lon: "---"
  });
  const [countryCode, setCountryCode] = useState("");

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentMapPosition({lat, lng})
  };

  const generateDigitalAddressSuccessCallback = () => {
    setLoading(false);
  }

  const generateNewDigitalAddress = (lat, lon) => {

    setLoading(true);

    apiGenerateDigitalAddress(
      lat,
      lon,
      address,
      setAddress,
      setCountryCode,
      generateDigitalAddressSuccessCallback
    )
  }

  useEffect(() => {
    console.log(currentMapPosition.lat, currentMapPosition.lng)
    generateNewDigitalAddress(currentMapPosition.lat, currentMapPosition.lng)
  }, [currentMapPosition])


  const successCurrentPositionCallback = position => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }

    setCurrentMapPosition(currentPosition);
    setCurrentMapZoom(13);

    if (currentPosition.lat !== "---" && currentPosition.lng !== "---") {
      setPageLoading(false);
    }

  };

  const errorCurrentPositionCallback = err => {
    console.log(err)
  };

  const onCurrentPositionClick = (e) => {
    // Success + Error callbacks in the args
    navigator.geolocation.getCurrentPosition(successCurrentPositionCallback, errorCurrentPositionCallback, {timeout: 10000});
  }


  useEffect(() => {

    setLoading(true);
    onCurrentPositionClick();
  }, [])


  return (

    <React.Fragment>
      {pageLoading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >

          <AdasLoading/>

        </Grid>
      ) : (

        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              title: "",
              category: null,
              description: "",
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().min(1).max(200).required('Address title is required'),
              category: Yup.mixed().required('Address category is required').nullable(false, 'Address category is required'),
              description: Yup.string().min(1),
            })}
            onSubmit={(data, actions) => {
              console.log(data.category)
              setShowWarning(false);
              actions.setSubmitting(true);
              setAddress({
                ...address,
                title: data.title,
                description: data.description,
                category: data.category
              })

              let form_data = {
                title: data.title,
                category:data.category,
                description: data.description,
                address: address.address,
                digital_address: address.digital_address,
                plus_code: address.plus_code,
                country_name: address.country_name,
                country_code: address.country_code,
                geo_lat: address.geo_lat,
                geo_lon: address.geo_lon,
                is_temporary: address.is_temporary
              }

              dispatchPostAddress(form_data, () => {
                setShowSuccessMsg(true);
                actions.resetForm({});
                actions.setSubmitting(false);
                navigate('/addresses');
              });

            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form
                encType={"multipart/form-data"}
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={4} lg={3} className={classes.searchGrid}>

                    <CardContent>
                      <Grid
                        container
                      >

                        <Box mb={1}>
                          <Typography
                            className={classes.pageTitle}
                            color="secondary"
                            variant="h2"
                          >
                            Add an address
                          </Typography>

                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            You can drag and drop the marker manually.
                          </Typography>
                        </Box>

                        {showSuccessMsg ? (
                          <Grid item xs={12}>
                            <Box mt={3}>
                              <Alert severity="success">The address was added successfully.</Alert>
                            </Box>
                          </Grid>) : (
                          <></>
                        )}

                        {showWarning ? (
                          <Grid item xs={12}>
                            <Box mt={3}>
                              <Alert severity="warning">An unexpected error occured. Please drag and drop your marker to
                                choose an address.</Alert>
                            </Box>
                          </Grid>) : (
                          <></>
                        )}

                        <Grid item
                              className={classes.searchGridItem}
                              xs={12}>
                          <LocationSearchInputComponent success_callback={setCurrentMapPosition}/>
                        </Grid>

                        <Grid
                          item
                          className={classes.searchGridItem}
                          xs={12}
                        >
                          <Box>
                            <Button
                              fullWidth
                              className={classes.greenBtn}
                              variant="contained"
                              onClick={onCurrentPositionClick}
                              startIcon={<MyLocationTwoToneIcon/>}
                            >
                              Use my current location
                            </Button>

                          </Box>
                        </Grid>

                        <Grid item
                              className={classes.searchGridItem}
                              xs={12}>

                          <Box mt={3}/>

                          <Box className={classes.addressBox}>
                            <Typography
                              className={classes.addressBoxTitle}
                              color="textSecondary"
                              variant="h6"
                            >
                              Digital address
                            </Typography>

                            <Typography
                              className={classes.digitalAddressBoxContent}
                              color="secondary"
                              variant="h2"
                            >
                              {address.digital_address}
                            </Typography>
                          </Box>

                          <Box className={classes.addressBox}>

                            <Box>
                              <Typography
                                className={classes.addressBoxTitle}
                                color="textSecondary"
                                variant="h6"
                              >
                                Address
                              </Typography>

                              <Typography
                                className={classes.addressBoxContent}
                                color="textPrimary"
                                variant="h6"
                              >
                                {address.address}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography
                                className={classes.addressBoxTitle}
                                color="textSecondary"
                                variant="h6"
                              >
                                Latitude,Longitude
                              </Typography>

                              <Typography
                                className={classes.addressBoxContent}
                                color="textPrimary"
                                variant="h6"
                              >
                                {address.geo_lat + "," + address.geo_lon}
                              </Typography>
                            </Box>

                          </Box>

                        </Grid>

                        <Grid
                          item
                          className={classes.searchGridItem}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={touched.title && errors.title}
                            label="Address title"
                            margin="normal"
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            variant="outlined"
                            placeholder="Eg. Home address, Work address, etc..."
                            InputLabelProps={{shrink: true}}
                          />


                        </Grid>

                        <Grid
                          item
                          className={classes.searchGridItem}
                          xs={12}
                        >
                          <AddressCategoryChooserInput
                            initialCategoryId={values.category}
                            setChosenCategoryId={(categoryId) => {
                              formikRef.current.setFieldValue("category", categoryId)
                            }}

                            customInputProps={{
                              onBlur:handleBlur,
                              onChange:handleChange,
                              error:Boolean(touched.category && errors.category)
                            }}

                          />

                          <p className={classes.FormError}>
                            {errors.category}
                          </p>


                        </Grid>


                        <Grid
                          item
                          className={classes.searchGridItem}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.description && errors.description)}
                            fullWidth
                            helperText={touched.description && errors.description}
                            label="Additional information"
                            margin="normal"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                            placeholder={"Eg. House number, floor ect..."}
                            InputLabelProps={{shrink: true}}
                          />
                        </Grid>


                      </Grid>
                    </CardContent>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      mr={2}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={loadingState.sub_page_loading}
                        onClick={handleSubmit}
                      >
                        Save address
                      </Button>
                    </Box>

                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={9} className={classes.mapGrid}>
                    <MapContainer currentPosition={currentMapPosition}
                                  currentMapZoom={currentMapZoom}
                                  onMarkerDragEnd={(e) => onMarkerDragEnd(e)}
                    >

                    </MapContainer>
                  </Grid>
                </Grid>
              </form>


            )}
          </Formik>
        </div>
      )}

    </React.Fragment>


  );
};

ProductCreateForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    loadingState: state.loadingReducer,
    authState: state.authReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPostAddress: (formData, success_cb) => {
      dispatch(postAddressAction(formData, success_cb))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreateForm);
