import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import {Formik} from 'formik';
import {patchMe} from "../../../redux/actions/userActions";
import {connect} from "react-redux";
import * as Yup from "yup";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import '../../../components/PhoneInput2/style.css'

const useStyles = makeStyles(() => ({
  root: {},
  FormError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  }
}));

const ProfileDetails = (props) => {
  const classes = useStyles();
  const formikRef = useRef(null);

  const {userState, loadingState} = props;

  return (

    <React.Fragment>
      <div >
        <Formik
          innerRef={formikRef}
          initialValues={{
            first_name: userState.first_name,
            last_name: userState.last_name,
            email: userState.email,
            phone_number: userState.phone_number,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').min(1).required('Email is required'),
            first_name: Yup.string().min(1).max(150).required('First name is required'),
            last_name: Yup.string().min(1).max(150).required('Last name is required'),
            phone_number: Yup.string().max(128).required('Phone number is required'),
          })}
          onSubmit={(data, actions) => {
            props.dispatchPatchMe(data);
            actions.setSubmitting(false);

          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
            <form
              encType={"multipart/form-data"}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Card style={{overflow:"inherit"}}>
                <CardHeader
                  subheader="The information can be edited"
                  title="Profile"
                />
                <Divider/>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.first_name && errors.first_name)}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        label="First name"
                        margin="normal"
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        label="Last name"
                        margin="normal"
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      {/*}
                      <TextField
                        error={Boolean(touched.phone_number && errors.phone_number)}
                        fullWidth
                        helperText={touched.phone_number && errors.phone_number}
                        label="Phone number"
                        margin="normal"
                        name="phone_number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values.phone_number}
                        variant="outlined"
                      />
                      {*/}
                      <PhoneInput
                        countryCodeEditable={false}
                        specialLabel={'Phone number'}
                        containerStyle={{marginTop:15}}
                        inputStyle={{width: '100%'}}

                        value={values.phone_number}
                        placeholder="Enter phone number"
                        //value={value}
                        onChange={(value, country, e, formattedValue)=>{
                          console.log(value)
                          formikRef.current.setFieldValue("phone_number", formattedValue)
                        }}
                      />
                      <p className={classes.FormError}>
                        {errors.phone_number}
                      </p>

                    </Grid>

                  </Grid>
                </CardContent>
                <Divider/>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={loadingState.sub_page_loading}
                    onClick={handleSubmit}
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>


          )}
        </Formik>
      </div>

    </React.Fragment>


  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    userState: state.userReducer,
    loadingState: state.loadingReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPatchMe: (formData) => {
      dispatch(patchMe(formData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
