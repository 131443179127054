import {pageLoading, subPageLoading} from "./loadingActions";
import {authActionLogin, authActionLogout} from "./authActions";
import axiosInterceptor from "../../utils/axiosInterceptor";


const userActionType = {
  SET: 'SET',
  UPDATE: 'UPDATE',

  // FETCH
  FETCH_USER_STARTED: 'FETCH_USER_STARTED',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILED: 'FETCH_USER_FAILED',
  // PUT
  PUT_USER_SUCCESS: 'PUT_USER_SUCCESS',
  PUT_USER_FAILED: 'PUT_USER_FAILED',
  // PATCH
  PATCH_USER_SUCCESS: 'PATCH_USER_SUCCESS',
  PATCH_PROFILE_PIC_SUCCESS: 'PATCH_PROFILE_PIC_SUCCESS',

}


function userActionSet(data) {
  return {
    type: userActionType.SET,
    payload: data
  };
}


const fetchUserSuccess = user => {
  return {
    type: userActionType.FETCH_USER_SUCCESS,
    payload: {
      user
    }
  };
};

const fetchUserFailed = error => {
  return {
    type: userActionType.FETCH_USER_FAILED,
    payload: {
      error
    }
  };
};

// Put

const putUserSuccess = user => {
  return {
    type: userActionType.FETCH_USER_SUCCESS,
    payload: {
      user
    }
  };
};

const putUserFailed = error => {
  return {
    type: userActionType.FETCH_USER_FAILED,
    payload: {
      error
    }
  };
};

// Patch

const patchUserSuccess = user => {
  return {
    type: userActionType.PATCH_USER_SUCCESS,
    payload: {
      user
    }
  };
};

const patchUserProfilePicSuccess = user => {
  return {
    type: userActionType.PATCH_PROFILE_PIC_SUCCESS,
    payload: {
      user
    }
  };
};


const userLoginAction = (data, formikActions, pageStateSetNonFieldError) => {
  return dispatch => {

    dispatch(subPageLoading(true));

    const apiUrl = 'users/registration/login/';

    pageStateSetNonFieldError("");

    axiosInterceptor.post(apiUrl, data)
      .then(response => {
        dispatch(authActionLogin(true, response.data.access_token));
        dispatch(fetchUserSuccess(response.data.user));
        // Initial fetch, sinon kaytra bug w kayb9aw khawine hta kat recharja lpage
      })
      .catch(error => {
        if (error.response) {
          let errors = error.response.data;
          formikActions.setErrors(errors);
          if (errors.non_field_errors) {
            pageStateSetNonFieldError(errors.non_field_errors);
          }
        }
        formikActions.setSubmitting(false);

      }).finally(() => {
      dispatch(subPageLoading(false));
    });

  }
}


const facebookLoginAction = (access_token) => {
  return dispatch => {

    dispatch(subPageLoading(true));

    let data = {
      'access_token': access_token,
    }

    axiosInterceptor.post('users/registration/facebook/', data)
      .then(response => {
        dispatch(authActionLogin(true, response.data.access_token));
        dispatch(fetchUserSuccess(response.data.user));
        // Initial fetch, sinon kaytra bug w kayb9aw khawine hta kat recharja lpage

      })
      .catch(error => {

      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  }
}

const googleLoginAction = (access_token) => {
  return dispatch => {

    dispatch(subPageLoading(true));

    let data = {
      'access_token': access_token,
    }

    axiosInterceptor.post('users/registration/google/', data)
      .then(response => {
        dispatch(authActionLogin(true, response.data.access_token));
        dispatch(fetchUserSuccess(response.data.user));
        // Initial fetch, sinon kaytra bug w kayb9aw khawine hta kat recharja lpage

      })
      .catch(error => {

      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  }
}


const fetchMe = () => {

  return dispatch => {
    dispatch(pageLoading(true));

    axiosInterceptor.get("users/me/")
      .then(res => {
        console.log("123")
        console.log(res.data)
        dispatch(fetchUserSuccess(res.data));
        dispatch(authActionLogin());
      })
      .catch(err => {
        dispatch(fetchUserFailed(err.message));

        // we remove the token from storage. Because he is not connected anyway and we cannot fetch him.
        dispatch(authActionLogout());
      }).finally(() => {
      //setTimeout(function() {}.bind(this), 1000)
      dispatch(pageLoading(false));
    });

  };

};


const putMe = (formData) => {
  return dispatch => {
    dispatch(subPageLoading(true));

    axiosInterceptor
      .put("users/me/update/", formData)
      .then(res => {
        dispatch(putUserSuccess(res.data));
      })
      .catch(err => {
        dispatch(putUserFailed(err.message));
      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  };
};

const patchMe = (formData) => {
  return dispatch => {
    dispatch(subPageLoading(true));
    axiosInterceptor
      .patch(
        "users/me/update/",
        formData,
      )
      .then(res => {
        dispatch(patchUserSuccess(res.data));
      })
      .catch(err => {
        dispatch(putUserFailed(err.message));
      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  };
};

const putProfilePicMe = (data) => {
  return dispatch => {
    dispatch(subPageLoading(true));

    const formData = new FormData();
    formData.append('profile_img', data.profile_img);

    axiosInterceptor
      .patch(
        "users/me/profile_img/",
        formData,
        {'Content-Type': 'application/json'}
      )
      .then(res => {
        dispatch(patchUserProfilePicSuccess(res.data));
      })
      .catch(err => {
        //console.log(err.message)
        dispatch(putUserFailed(err.message));
      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  };
};

const updatePasswordAction = (formData, setShowSuccessMsg, formikActions) => {

  // formData: new_password1 / new_password2
  setShowSuccessMsg(false);

  return dispatch => {
    dispatch(subPageLoading(true));
    axiosInterceptor
      .post(
        "/users/registration/password/change/",
        formData,
      )
      .then(res => {
        // put success msg
        setShowSuccessMsg(true);
      })
      .catch(error => {
        let errors = error.response.data;
        formikActions.setErrors(errors);
        //console.log(errors)
      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  };
};



export {
  userActionType,
  userActionSet,
  fetchMe,
  putMe,
  patchMe,
  putProfilePicMe,
  userLoginAction,
  facebookLoginAction,
  googleLoginAction,
  updatePasswordAction,

};
