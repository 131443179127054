import {createStore, applyMiddleware, compose, combineReducers} from "redux";
import thunk from 'redux-thunk';
import userReducer from "./reducers/userReducer";
import loadingReducer from "./reducers/loadingReducer";
import authReducer from "./reducers/authReducer";
import {DEBUG} from "../utils/globals";
import addressReducer from "./reducers/addressReducer";
import addressTitlesAutocompleteReducer from "./reducers/addressTitleAutocompleteReducer";


let store = ""

const rootReducer = combineReducers({
  userReducer,
  loadingReducer,
  authReducer,
  addressReducer,
  addressTitlesAutocompleteReducer,
})

if (DEBUG) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
  ));
}

else{
  store = createStore(rootReducer, applyMiddleware(thunk));
}


export default store;
