import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {useParams} from "react-router";
import UnprotectedPage from "../../components/UnprotectedPage";
import axiosInterceptor from "../../utils/axiosInterceptor";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgotPasswordConfirm = () => {
  let {uid} = useParams();
  let {token1} = useParams();
  let {token2} = useParams();
  const token = token1+"-"+token2;
  const classes = useStyles();

  const [nonFieldError, setNonFieldError] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);


  return (
    <UnprotectedPage
      className={classes.root}
      title="Password reset confirmation"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {showSuccessMsg ? (
            <Container maxWidth="sm">
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Forgot password request
                </Typography>

              </Box>

              <Alert severity="success">
                <AlertTitle>Your password has been set</AlertTitle>
                <p>
                  You may go ahead and <Link
                  component={RouterLink}
                  to="/login"
                  variant="h6"
                >
                  Sign in
                </Link> now.
                </p>
              </Alert>
            </Container>
          ) :
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                new_password1: '',
                new_password2: '',
              }}
              validationSchema={
                Yup.object().shape({
                  new_password1: Yup.string().min(1).max(255).required('password is required'),
                  new_password2: Yup.string().min(1).max(255).required('password confirmation is required').oneOf([Yup.ref('new_password1'), null], 'The two passwords must match'),
                })
              }
              onSubmit={(data, actions) => {
                const apiUrl = 'users/registration/password/reset/confirm/';

                setNonFieldError("");

                data.uid = uid;
                data.token = token;

                axiosInterceptor.post(apiUrl, data)
                  .then(response => {
                    setShowSuccessMsg(true);
                  })
                  .catch(error => {
                    let errors = error.response.data;

                    actions.setErrors(errors);

                    if (errors.uid || errors.token) {
                      setNonFieldError("The password reset link was invalid, possibly because it has already been used. Please request a new password reset.");
                    }


                  }).finally(() => {
                  actions.setSubmitting(false);
                });

              }}
            >
              {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (

                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Password reset confirmation
                    </Typography>

                  </Box>

                  {nonFieldError !== "" ? (
                    <Alert severity="error">{nonFieldError}</Alert>
                  ) : ""}


                  <TextField
                    error={Boolean(touched.new_password1 && errors.new_password1)}
                    fullWidth
                    helperText={touched.new_password1 && errors.new_password1}
                    label="Password"
                    margin="normal"
                    name="new_password1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password1}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.new_password2 && errors.new_password2)}
                    fullWidth
                    helperText={touched.new_password2 && errors.new_password2}
                    label="Repeat password"
                    margin="normal"
                    name="new_password2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password2}
                    variant="outlined"
                  />


                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Change password
                    </Button>
                  </Box>

                </form>

              )}
            </Formik>
          </Container>
        }
      </Box>
    </UnprotectedPage>
  );
};

export default ForgotPasswordConfirm;
