import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: "#2c83f6",
    },
    secondary: {
      main: "#001260",
    },
    tertiary:{
      main: "#E9A600",
      dark: "#cb9102",
      light: "#ffdeb1",
    },
    danger:{
      main: "#f44336",
      dark: "#ba3126",
      light: "#f88179",
    },
    green:{
      main: "#60c013",
      dark: "#54A711",
      light: "#7BE920",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
  },

  shadows,
  typography
});

export default theme;
