import {subPageLoading} from "./loadingActions";
import axiosInterceptor from "../../utils/axiosInterceptor";


const addressActionType = {
  // FETCH ONE
  FETCH_ADDRESS_STARTED: 'FETCH_ADDRESS_STARTED',
  FETCH_ADDRESS_SUCCESS: 'FETCH_ADDRESS_SUCCESS',
  FETCH_ADDRESS_FAILED: 'FETCH_ADDRESS_FAILED',
  // FETCH MULTIPLE
  FETCH_ADDRESSES_STARTED: 'FETCH_ADDRESSES_STARTED',
  FETCH_ADDRESSES_SUCCESS: 'FETCH_ADDRESSES_SUCCESS',
  FETCH_ADDRESSES_EMPTY: 'FETCH_ADDRESSES_EMPTY',
  FETCH_ADDRESSES_FAILED: 'FETCH_ADDRESSES_FAILED',
  // POST
  POST_ADDRESS_SUCCESS: 'POST_ADDRESS_SUCCESS',
  POST_ADDRESS_FAILED: 'POST_ADDRESS_FAILED',
  // PUT
  PUT_ADDRESS_SUCCESS: 'PUT_ADDRESS_SUCCESS',
  PUT_ADDRESS_FAILED: 'PUT_ADDRESS_FAILED',
  // PATCH
  PATCH_ADDRESS_SUCCESS: 'PATCH_ADDRESS_SUCCESS',
  // DELETE
  DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAILED: 'DELETE_ADDRESS_FAILED',
}

// GET

const addressesFetchSuccess = (response) => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.FETCH_ADDRESSES_SUCCESS,
      payload: {
        response
      },
    })
  }
}

const addressFetchSuccess = (response) => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.FETCH_ADDRESS_SUCCESS,
      payload: {
        response
      },
    })
  }
}

const addressesFetchEmpty = () => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.FETCH_ADDRESSES_EMPTY,
      payload: "",
    })
  }
}

const addressesFetchFailed = () => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.FETCH_ADDRESSES_FAILED,
      payload: "",
    })
  }
}

// POST

const addressPostSuccess = (response) => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.POST_ADDRESS_SUCCESS,
      payload: {
        response
      },
    })
  }
}

const addressPostFailed = () => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.POST_ADDRESS_FAILED,
      payload: "",
    })
  }
}

// Delete


const addressDeleteSuccess = (id) => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.DELETE_ADDRESS_SUCCESS,
      payload: {
        id
      },
    })
  }
}

const addressDeleteFailed = () => {
  return (dispatch) => {
    dispatch({
      type: addressActionType.DELETE_ADDRESS_FAILED,
      payload: "",
    })
  }
}

/// Actions




const fetchAddressesAction = (pageNumber, searchKeywords) => {

  return dispatch => {
    dispatch(subPageLoading(true));

    let page_number = "1"

    if (pageNumber && !isNaN(pageNumber)) {
      page_number = pageNumber;
    }
    if (searchKeywords === undefined)
      searchKeywords = ""

    let apiUrl = `addresses/?page=${page_number}&search=${searchKeywords}`;


    axiosInterceptor
      .get(apiUrl)
      .then(res => {
        dispatch(addressesFetchSuccess(res.data));
      })
      .catch(err => {
        if (err.status === 404) {
          dispatch(addressesFetchEmpty());
        }
        dispatch(addressesFetchFailed(err.message));
      }).finally(() => {
      dispatch(subPageLoading(false));
    });

  };

};


const fetchAddressAction = (id, setAddress, setAddress404 = null, successCallback) => {

  return dispatch => {
    dispatch(subPageLoading(true));

    axiosInterceptor
      .get(`addresses/${id}/`)
      .then(res => {
        setAddress(res.data);
        successCallback(res.data);
        dispatch(addressFetchSuccess());
      })
      .catch(err => {
        dispatch(addressesFetchFailed(err.message));
        console.log(err)
        if(err.response){
          if (setAddress404 !== null && err.response.status === 404) {
            setAddress404(true);
          }
        }
      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  };
};

const patchAddressAction = (id, formData,success_cb) => {
  // formData = address, title, description
  // data = digital_address, country_name, country_code, geo_lat, geo_lon

  return dispatch => {
    dispatch(subPageLoading(true));

    axiosInterceptor
      .patch(`addresses/${id}/`, formData)
      .then(res => {
        success_cb();
        dispatch(addressPostSuccess(res.data));
      })
      .catch(err => {
        dispatch(addressPostFailed(err.message));
      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  };
};

const postAddressAction = (formData, success_callback) => {
  // formData = address, title, description
  // data = digital_address, country_name, country_code, geo_lat, geo_lon


  return dispatch => {
    dispatch(subPageLoading(true));

    axiosInterceptor
      .post("addresses/", formData)
      .then(res => {
        success_callback();
        dispatch(addressPostSuccess(res.data));
      })
      .catch(err => {
        dispatch(addressPostFailed(err.message));
      }).finally(() => {
      dispatch(subPageLoading(false));
    });
  };
};


const deleteAddressAction = (id, success_callback) => {

  return dispatch => {
    dispatch(subPageLoading(true));

    axiosInterceptor
      .delete(`addresses/${id}/`)
      .then(res => {
        success_callback();
        dispatch(addressDeleteSuccess(id))
      })
      .catch(err => {
        dispatch(addressDeleteFailed())
      }).finally(() => {
        dispatch(subPageLoading(false));
    });
  };
};

export {
  addressActionType,
  addressesFetchEmpty,
  fetchAddressAction,
  fetchAddressesAction,
  postAddressAction,
  patchAddressAction,
  deleteAddressAction,
};
