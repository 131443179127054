





export const DEBUG = process.env.REACT_APP_DEBUG;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const JWT_TOKEN_NAME = process.env.REACT_APP_JWT_TOKEN_NAME;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
// for google maps
// google maps + google places script loaded in index.html
export const GOOGLE_OAUTH_CLIENT_KEY = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_KEY;

// Api
export const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
// recaptcha
export const REACT_APP_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
