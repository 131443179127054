import {userActionType} from "../actions/userActions";


const initialState = {
  "id": 0,
  "email": "",
  "first_name": "",
  "last_name": "",
  "profile_img": {
    "img200x200": null,
    "original": null
  },
  "phone_number": 0,
  "organizations": [],
  "teams": [],
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case userActionType.FETCH_USER_SUCCESS:
      return {
        ...action.payload.user,
      }
    case userActionType.PATCH_USER_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
      }
    case userActionType.PATCH_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
      }
    case userActionType.FETCH_USER_FAILED:
      return {
        ...state,
      }

    case userActionType.PUT_USER_SUCCESS:
      return {
        ...action.payload.user,
      }
    case userActionType.PUT_USER_FAILED:
      return {
        ...state,
      }


    case userActionType.UPDATE:
      break;
    default:
      return state;
  }
}

export default userReducer;
