import React, {useEffect, useRef, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { CountryRegionData } from "react-country-region-selector";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import '../../components/PhoneInput2/style.css'

import ReCAPTCHA from "react-google-recaptcha";

import UnprotectedPage from "../../components/UnprotectedPage";
import axiosInterceptor from "../../utils/axiosInterceptor";
import {REACT_APP_RECAPTCHA_SITE_KEY} from "../../utils/globals";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark, // for gradient
    background: "linear-gradient(135deg, rgba(221,251,251,1) 0%, rgba(250,237,234,1) 100%)",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    minHeight:"100%",
    [theme.breakpoints.up('sm')]: {
      //height: "100%", // causes visual bug
    },
  },
  pageHeight: {
    //backgroundColor: "#f4f6f8",
    [theme.breakpoints.up('sm')]: {
      height: "100%",
    },
  },
  inputBgColor:{
    backgroundColor:"white",
  },

  FormError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  },
}));

const RegisterView = () => {
  const classes = useStyles();
  const recaptchaRef = React.createRef();
  const formikRef = useRef(null);

  const [nonFieldError, setNonFieldError] = useState("");
  const [registrationDone, setRegistrationDone] = useState(false);

  return (
    <UnprotectedPage
      className={classes.root}
      title="Register for business"
    >
      <Box
        className={classes.pageHeight}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            innerRef={formikRef}
            initialValues={{
              email: '',
              first_name: '',
              last_name: '',
              phone_number: '+256',
              password1: '',
              password2: '',
              business_name : '',
              business_phone_number : '+256',
              business_country_name : '',
              business_country_code : '',
              business_email : '',
              business_website : '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                recaptcha: Yup.string().required('Recaptcha is required'),

                email: Yup.string().min(1).max(150).email('Must be a valid email').min(1).required('Email is required'),
                first_name: Yup.string().min(1).max(150).required('First name is required'),
                last_name: Yup.string().min(1).max(150).required('Last name is required'),
                phone_number: Yup.string().min(1).max(128).required('Phone number is required'),
                password1: Yup.string().min(8).max(128).required('password is required'),
                password2: Yup.string().min(8).max(128).required('password confirmation is required').oneOf([Yup.ref('password1'), null], 'The two passwords must match'),


                business_name : Yup.string().min(1).max(200).required('Business name is required'),
                business_phone_number : Yup.string().min(1).max(128).required('Phone number is required'),
                business_country_name : Yup.string().min(1).max(100).required('Country is required'),
                business_country_code : Yup.string().min(1).max(10).required('Country is required'),
                business_email : Yup.string().min(1).max(150).email('Must be a valid email').required('Email is required'),
                business_website : Yup.string().max(150),

                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={(data, actions) => {

              actions.setSubmitting(true);

              const apiUrl = 'users/registration/business/';

              setNonFieldError("");

              axiosInterceptor.post(apiUrl, data)
                .then(response => {
                  setRegistrationDone(true);
                })
                .catch(error => {
                  console.log(error)
                  console.log(error.response)
                  let errors = error.response.data;

                  actions.setErrors(errors);

                  if (errors.non_field_errors) {
                    setNonFieldError(errors.non_field_errors);
                  }

                }).finally(() => {
                actions.setSubmitting(false);
              });

            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (

              <React.Fragment>
                {
                  registrationDone ? (
                    <Box mb={3}>

                        <Alert severity="success">
                          <AlertTitle>You've successfully signed up for ADAS</AlertTitle>
                          We've sent you a link to confirm your email address. Please check your inbox. <strong>It could take up to 10 minutes to show up in your inbox.</strong>
                        </Alert>
                    </Box>
                  ) :
              <form onSubmit={handleSubmit}>

                <Box mb={3} display="flex"  justifyContent="center">
                  <img
                    alt="Logo"
                    src="/static/logo-horizontal.svg"
                  />
                </Box>


                <Box  display="flex"  justifyContent="center">
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new business account
                  </Typography>
                </Box>

                {nonFieldError !== "" ? (
                  <Alert severity="error">{nonFieldError}</Alert>
                ): ""}

                  <Grid  container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        InputProps={{
                          className: classes.inputBgColor,
                        }}
                        error={Boolean(touched.first_name && errors.first_name)}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        label="First name"
                        margin="normal"
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        InputProps={{
                          className: classes.inputBgColor,
                        }}
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        label="Last name"
                        margin="normal"
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        variant="outlined"
                      />
                    </Grid>

                  </Grid >



                <TextField
                  InputProps={{
                    className: classes.inputBgColor,
                  }}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                {/*}
                <TextField
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  fullWidth
                  helperText={touched.phone_number && errors.phone_number}
                  label="Phone number"
                  margin="normal"
                  name="phone_number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone_number}
                  variant="outlined"
                />
                {*/}

                <PhoneInput
                  countryCodeEditable={false}
                  specialLabel={'Phone number'}
                  containerStyle={{marginTop:15}}
                  inputStyle={{width: '100%'}}

                  value={values.phone_number}
                  placeholder="Enter phone number"
                  //value={value}
                  onChange={(value, country, e, formattedValue)=>{
                    console.log(value)
                    formikRef.current.setFieldValue("phone_number", formattedValue)
                  }}
                />
                <p className={classes.FormError}>
                  {errors.phone_number}
                </p>

                <TextField
                  InputProps={{
                    className: classes.inputBgColor,
                  }}
                  error={Boolean(touched.password1 && errors.password1)}
                  fullWidth
                  helperText={touched.password1 && errors.password1}
                  label="Password"
                  margin="normal"
                  name="password1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password1}
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    className: classes.inputBgColor,
                  }}
                  error={Boolean(touched.password2 && errors.password2)}
                  fullWidth
                  helperText={touched.password2 && errors.password2}
                  label="Repeat password"
                  margin="normal"
                  name="password2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password2}
                  variant="outlined"
                />

                <Grid  container >
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        className: classes.inputBgColor,
                      }}
                      error={Boolean(touched.business_name && errors.business_name)}
                      fullWidth
                      helperText={touched.business_name && errors.business_name}
                      label="Business name"
                      margin="normal"
                      name="business_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.business_name}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PhoneInput
                      countryCodeEditable={false}
                      specialLabel={'Business Phone number'}
                      containerStyle={{marginTop:15}}
                      inputStyle={{width: '100%'}}

                      value={values.business_phone_number}
                      placeholder="Enter phone number"
                      //value={value}
                      onChange={(value, country, e, formattedValue)=>{
                        console.log(value)
                        formikRef.current.setFieldValue("business_phone_number", formattedValue)
                      }}
                    />
                    <p className={classes.FormError}>
                      {errors.business_phone_number}
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        className: classes.inputBgColor,
                      }}
                      fullWidth
                      helperText={touched.business_country_name && errors.business_country_name}
                      margin="normal"
                      error={Boolean(touched.business_country_name && errors.business_country_name)}
                      label="Business country"
                      select
                      name="business_country_name"
                      onBlur={handleBlur}
                      onChange={(event)=>{
                        console.log(event.target)
                        console.log(event.target.value[1])
                        formikRef.current.setFieldValue("business_country_name", event.target.value[0])
                        formikRef.current.setFieldValue("business_country_code", event.target.value[1])
                      }}
                      variant="outlined"
                    >
                      {CountryRegionData.map((option, index) => (
                        <MenuItem key={option[0]} value={option}>
                          {option[0]}
                        </MenuItem>
                      ))}
                    </TextField>


                  </Grid>



                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        className: classes.inputBgColor,
                      }}
                      error={Boolean(touched.business_email && errors.business_email)}
                      fullWidth
                      helperText={touched.business_email && errors.business_email}
                      label="Business Email"
                      margin="normal"
                      name="business_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.business_email}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        className: classes.inputBgColor,
                      }}
                      error={Boolean(touched.business_website && errors.business_website)}
                      fullWidth
                      helperText={touched.business_website && errors.business_website}
                      label="Business website (Optional)"
                      margin="normal"
                      name="business_website"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.business_website}
                      variant="outlined"
                    />
                  </Grid>

                </Grid >

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={()=>{
                    formikRef.current.setFieldValue("recaptcha", recaptchaRef.current.getValue())
                  }}
                />

                <p className={classes.FormError}>
                  {errors.recaptcha}
                </p>

                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <a href="https://adas.app/terms-of-use" target={'blank'}>
                      <Typography
                        style={{
                          display: "inline",
                          color: "#2c83f6",
                          textDecoration: "underline",
                        }}
                        underline="always"
                                  variant="h6">
                        Terms and Conditions
                      </Typography>
                    </a>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>

                }
              </React.Fragment>
            )}
          </Formik>
        </Container>
      </Box>
    </UnprotectedPage>
  );
};



export default RegisterView;
