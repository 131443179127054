import {JWT_TOKEN_NAME} from "./globals";


const setJwt = (token) => {
  localStorage.setItem(JWT_TOKEN_NAME, token);
}

const getJwt = () => {
  return localStorage.getItem(JWT_TOKEN_NAME);

}

const removeJwt = () => {
  localStorage.removeItem(JWT_TOKEN_NAME);
}


export {
  setJwt,
  getJwt,
  removeJwt,
};
