import axiosInterceptor from "../../utils/axiosInterceptor";


const apiReverseGeocodeAddress = (lat, lon, address, setAddress,setCountryCode, successCallback) => {

  axiosInterceptor
    .get(`/addresses/reverse-geocode-address/?lat=${lat}&lon=${lon}`)
    .then(res => {
      setCountryCode(res.data.country_code);
      setAddress({
        ...address,
        plus_code: res.data.plus_code,
        geo_lat: res.data.lat,
        geo_lon: res.data.lon,
        address: res.data.formatted_address,
        country_name: res.data.country_name,
        country_code: res.data.country_code,
      });
      successCallback();
    })
    .catch(err => {
      console.log(err.response.data)
      // TODO : Catch 404, 400 etc
    })
    .finally(() => {
        // ...
      }
    );
};

const apiGenerateDigitalAddress = (lat, lon, address, setAddress,setCountryCode, successCallback) => {

  axiosInterceptor
    .get(`/addresses/generate-digital-address/?lat=${lat}&lon=${lon}`)
    .then(res => {
      setCountryCode(res.data.country_code);
      setAddress({
        ...address,
        plus_code: res.data.plus_code,
        geo_lat: res.data.lat,
        geo_lon: res.data.lon,
        address: res.data.formatted_address,
        country_name: res.data.country_name,
        country_code: res.data.country_code,
        digital_address: res.data.digital_address,
      });
      successCallback();
    })
    .catch(err => {
      console.log(err.response.data)
      // TODO : Catch 404, 400 etc
    })
    .finally(() => {
        // ...
      }
    );
};

export {
  apiReverseGeocodeAddress,
  apiGenerateDigitalAddress
}
