import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useEffect} from 'react';
import {useRoutes} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import {fetchMe} from "./redux/actions/userActions";
import {connect} from "react-redux";
import AdasLoading from "./components/AdasLoading";
import {fetchAddressTitlesAutocompleteAction} from "./redux/actions/addressTitleAutocompleteActions";

const App = (props) => {
  const routing = useRoutes(routes);

  const {loadingState, fetchMeDispatch, fetchAddressTitlesAutocompleteDispatch} = props;

  useEffect(() => {
    fetchMeDispatch();
    fetchAddressTitlesAutocompleteDispatch();
  }, [])

  if (loadingState.page_loading) {
    return <AdasLoading/>
  } else {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles/>
        {routing}
      </ThemeProvider>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    loadingState: state.loadingReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMeDispatch: () => {
      dispatch(fetchMe());
    },
    fetchAddressTitlesAutocompleteDispatch : () =>{
      dispatch(fetchAddressTitlesAutocompleteAction());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
