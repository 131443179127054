import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    backgroundColor:"white",
    padding:"3px",
    borderRadius:"100%",
    maxHeight:"44px",
  },
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <img
      className={classes.logo}
      alt="Logo"
      src="/static/logo-isotype.svg"
      {...props}
    />
  );
};

export default Logo;
