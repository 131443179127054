


const authActionType = {
  LOGIN: 'LOGIN',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
}


const authActionLogin = (isLoggedIn,token) => {
  return (dispatch) => {
    dispatch({
      type: authActionType.LOGIN,
      payload: {
        isLoggedIn,
        token,
      },
    })
  }
}

// Delete token if login error
const authLoginErrorAction = (isLoggedIn,token) => {
  return (dispatch) => {
    dispatch({
      type: authActionType.LOGIN_ERROR,
      payload: {
      },
    })
  }
}

const authActionLogout = () => {
  return (dispatch) => {
    dispatch({
      type: authActionType.LOGOUT,
      payload: {},
    })
  }
}


export {
  authActionLogin,
  authLoginErrorAction,
  authActionLogout,
  authActionType
} ;
