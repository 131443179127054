import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles, Box, Hidden, IconButton, Typography
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import {User as UserIcon} from "react-feather";
import InputIcon from "@material-ui/icons/Input";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
  toolbar: {
    height: 64
  }
}));

const TopBar = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {authState,className, onMobileNavOpen, ...rest} = props;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo/>

        </RouterLink>
        {authState.isLoggedIn ? (
          <React.Fragment>

            <Hidden mdDown>

              <Button color="inherit" onClick={() => {
                navigate('/');
              }}>
                <ArrowBackIosIcon/>
                <Typography
                  align="center"
                  style={{color:"#FFFFFF"}}
                  variant="subtitle1"
                >
                  Go back
                </Typography>
              </Button>
            </Hidden>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        {authState.isLoggedIn ? (
          <React.Fragment>
            <Box flexGrow={1}/>

            <Hidden mdDown>
              {/*}
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon/>
            </Badge>
          </IconButton>
          */}

              <IconButton color="inherit" onClick={() => {
                navigate('/account');
              }}>
                <UserIcon/>
              </IconButton>

              <IconButton color="inherit" onClick={() => {
                navigate('/logout');
              }}>
                <InputIcon/>
              </IconButton>
            </Hidden>

            {/*}

            <Hidden lgUp>
              <IconButton
                color="inherit"
                onClick={onMobileNavOpen}
              >
                <MenuIcon/>
              </IconButton>
            </Hidden>
            {*/}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}


      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    authState: state.authReducer,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
