import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import RoomIcon from "@material-ui/icons/Room";
import IconButton from "@material-ui/core/IconButton";
import EditLocationRoundedIcon from '@material-ui/icons/EditLocationRounded';
import ShareIcon from '@material-ui/icons/Share';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  updateIcon: {
    color: theme.palette.tertiary.main,
  },
  digitalAddressCss:{
    fontSize:"2.5em",
  }
}));

const ProductCard = ({className, product,onDeleteClick, ...rest}) => {
  const classes = useStyles();


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="center" mt={1} mb={0}
        >
          <RoomIcon color={"primary"}/>
          <Typography
            align="center"
            color="primary"
            gutterBottom
            variant="h4"
          >
            {product.title} {product.category_details ? "- " + product.category_details.title : null}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h3"
            className={classes.digitalAddressCss}
          >
            {product.digital_address}
          </Typography>
        </Box>
        <Box>

          <Typography
            align="center"
            color="textPrimary"
            variant="body2"
          >
            {product.address}
          </Typography>
        </Box>
      </CardContent>
      <Box flexGrow={1}/>
      <Divider/>
      <Box p={2}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >

          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >

            <IconButton aria-label="Delete address" onClick={onDeleteClick}>
              <DeleteForeverIcon color="error"/>
            </IconButton>

            <RouterLink to={`/addresses/${product.id}/edit`}>
              <IconButton aria-label="Update address">
                <EditLocationRoundedIcon className={classes.updateIcon}/>
              </IconButton>
            </RouterLink>

            <RouterLink to={`/search?da=${product.digital_address}`}>
              <IconButton aria-label="Share address">
                <ShareIcon color="primary"/>
              </IconButton>
            </RouterLink>

          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
