import axios from "axios";
import {getJwt} from "./jwt";
import {REACT_APP_API_BASE_URL} from "./globals";


const BASE_URL = REACT_APP_API_BASE_URL;
//const BASE_URL = 'http://127.0.0.1:8000/api/v1/';


const axiosInterceptor = axios.create({
  baseURL: BASE_URL
});

axiosInterceptor.interceptors.request.use(
  function(config) {
    const token = getJwt();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
