import React from 'react';
import {makeStyles} from '@material-ui/styles';

import Autosuggest from 'react-autosuggest';


const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  input: {
    width: "100%",
    height: "50px",
    padding: "10px 20px",
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: "300",
    fontSize: "16px",
    border: "1px solid #aaa",
    borderRadius: "4px"
  },
  inputFocused: {
    outlineStyle: "none"
  },

  inputOpen: {
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0"
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: "49px",
    width: "100%",
    border: "1px solid #aaaaaa",
    backgroundColor: "#fff",
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 300,
    fontSize: "20px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    zIndex: 2,
  },
  suggestionsList: {
    margin: "0",
    padding: "0",
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
    height: "100%",
    borderTop: "1px solid #ddd",
  },
  suggestionFirst: {
    borderTop: "0",
  },
  suggestionHighlighted: {
    borderColor: "#0C7EAF",
    backgroundColor: "#0C7EAF",
    color: "#fff",
  },
  highlight: {
    color: "#ee0000",
    fontWeight: "400",

    suggestionHighlighted: {
      color: "#120000",
    },
  },
});

const CustomAutoSuggest = (props) => {
  const theme = useStyles();
  return (
    <Autosuggest
      suggestions={props.suggestions}
      onSuggestionsFetchRequested={props.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={props.onSuggestionsClearRequested}
      getSuggestionValue={props.getSuggestionValue}
      renderSuggestion={props.renderSuggestion}
      inputProps={props.inputProps}
      theme={theme}
    />
  )
}

export default CustomAutoSuggest;
