import React, {useEffect, useState} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {LocationOn} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  MenuContainer: {
    position: "absolute",
    top: "65px",
    zIndex: "100",
    width: "100%",
    boxShadow:"0 0 4px 0px rgb(0 0 0 / 56%), 0 2px 2px -2px rgb(0 0 0 / 76%)",
  },
  LocationContainer: {
    position: "relative",
  },
  MenuContainerItem: {
    padding: "0",
  },
  paddingContainerItem:{
    padding:"10px",
  }
}));


const LocationSearchInputComponent = (props) => {
  const classes = useStyles();
  const [address, setAddress] = useState('')

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        //console.log('Success', latLng);
        props.success_callback(latLng)
      })
      .catch(error => {
        console.error('Error', error)
      });
  };


  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div className={classes.LocationContainer}>
          {/*}
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          {*/}
          <TextField
            fullWidth
            label="Search"
            margin="normal"
            name="title"
            variant="outlined"
            placeholder="Type your keywords..."
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })
            }}
          />

          <div className={classes.MenuContainer}>
            {loading && (
              <MenuItem className={classes.MenuContainerItem} value={"Loading..."}>
                <Grid className={classes.paddingContainerItem} container alignItems="center" >
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      Loading...
                    </Typography>
                  </Grid>

                </Grid>
              </MenuItem>
            )}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                : {backgroundColor: '#ffffff', cursor: 'pointer'};
              return (
                <MenuItem className={classes.MenuContainerItem} value={suggestion.description}>

                    <Grid  container alignItems="center" {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}>
                      <Grid className={classes.paddingContainerItem} item xs>


                        <Typography variant="body2" color="textSecondary">
                          <LocationOn className={classes.icon}/>
                          {suggestion.description}
                        </Typography>
                      </Grid>

                    </Grid>
                </MenuItem>


              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default LocationSearchInputComponent;
