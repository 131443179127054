import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {GOOGLE_API_KEY} from "../utils/globals";


const MapContainer = (props) => {

  const mapStyles = {
    height: "100%",
    width: "100%"};

  // loaded in index.html

  return (
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={props.currentMapZoom}
        center={props.currentPosition}>
        <Marker
          position={props.currentPosition}
          onDragEnd={props.onMarkerDragEnd }
          draggable={true}
        />

        </GoogleMap>
  )
}

export default MapContainer;
