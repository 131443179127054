import React from 'react';
import {
  makeStyles
} from '@material-ui/core';
import ProtectedPage from 'src/components/ProtectedPage';
import ProductCreateForm from './ProductCreateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark, // for gradient
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  }
}));

const ProductCreateView = () => {
  const classes = useStyles();

  return (
    <ProtectedPage
      className={classes.root}
      title="Add an address"
    >
      <ProductCreateForm />
    </ProtectedPage>
  );
};

export default ProductCreateView;
