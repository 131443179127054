import axiosInterceptor from "../../utils/axiosInterceptor";


const fetchAddressCategories = (pageNumber, searchKeywords, successCallback, finallyCallback) => {

  let page_number = "1"

  if (pageNumber && !isNaN(pageNumber)) {
    page_number = pageNumber;
  }
  if (searchKeywords === undefined)
    searchKeywords = ""

  let apiUrl = `categories/?page=${page_number}&search=${searchKeywords}`;

  axiosInterceptor
    .get(apiUrl)
    .then(res => {
      successCallback(res.data);
    })
    .catch(err => {

    }).finally(() => {
    finallyCallback();
  });

};


export {
  fetchAddressCategories,
}
