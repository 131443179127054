import React, {Fragment, useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Button, Dialog,
  DialogActions, DialogContent, DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, TextField
} from "@material-ui/core";
import {fetchAddressCategories} from "../../redux/api/categories";
import AdasLoading from "../AdasLoading";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from "@material-ui/core/Typography";
import {findCategoryParentRecursively, findCategoryRecursively} from "../../utils/filters";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  MenuContainer: {
    position: "absolute",
    top: "65px",
    zIndex: "100",
    width: "100%",
    boxShadow: "0 0 4px 0px rgb(0 0 0 / 56%), 0 2px 2px -2px rgb(0 0 0 / 76%)",
  },
  LocationContainer: {
    position: "relative",
  },
  MenuContainerItem: {
    padding: "0",
  },
  paddingContainerItem: {
    padding: "10px",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "16px",
    width: "448px",
  },

  modalPadding: {
    padding: theme.spacing(2, 1)
  },

  dialogContentPadding: {
    padding: theme.spacing(2, 0)
  },
  categoryListItem: {
    padding: theme.spacing(1, 3)
  },
  paddingZero: {
    padding: 0,
  },
  cursorPointer: {
    cursor: "pointer !important",
  }

}));


const AddressCategoryChooserInput = (props) => {
  const classes = useStyles();

  const {initialCategoryId, setChosenCategoryId, customInputProps} = props;

  const [loading, setLoading] = useState(true);
  // Modal
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Reset categories to root
    setDisplayedCategories(
      {
        id: categoryInitialState.id,
        title: categoryInitialState.title,
        slug: categoryInitialState.slug,
        children: categories.results
      }
    );
    // Reset Previous chosen category to root
  };


  const handleBack = () => {
    //console.log(displayedCategories)
    if (displayedCategories.id === null) {
      handleClose();
    } else {
      let currentCategoryId = displayedCategories.id;

      let parentCategory = findCategoryParentRecursively(currentCategoryId, categories.results)

      if (parentCategory === null) {
        setDisplayedCategories({
          ...categoryInitialState,
          children: categories.results
        });
      } else {
        setDisplayedCategories(parentCategory);
      }

    }
  };


  //// End Modal

  const categoryInitialState = {
    "id": null,
    "title": "",
    "slug": "",
    "children": []
  }


  const [categories, setCategories] = useState(
    {
      "count": 0,
      "next": null,
      "previous": null,
      "results": []
    }
  )

  const [displayedCategories, setDisplayedCategories] = useState({
      "id": 0,
      "title": "",
      "slug": "",
      "children": []
    }
  )

  const [chosenCategory, setChosenCategory] = useState(categoryInitialState)

  useEffect(() => {
    if (chosenCategory.id !== null) {
      setChosenCategoryId(chosenCategory.id);
    }
    handleClose();
  }, [chosenCategory])


  const fetchCategoriesFinallyCallback = () => {
    setLoading(false);
  }

  const fetchCategoriesSuccessCallback = (response_data) => {
    let initialCategory = null;
    let initialDisplayedCategories = {
      ...displayedCategories,
      children: response_data.results
    };

    if (initialCategoryId !== null) {
      initialCategory = findCategoryRecursively(initialCategoryId, response_data.results)
    }

    if (initialCategory !== null) {
      initialDisplayedCategories = {
        id: initialCategory.id,
        title: initialCategory.title,
        slug: initialCategory.slug,
        children: initialCategory.children
      }
      setChosenCategory(initialCategory);
    }

    setCategories(response_data);
    setDisplayedCategories(
      {
        id: categoryInitialState.id,
        title: categoryInitialState.title,
        slug: categoryInitialState.slug,
        children: response_data.results
      }
    );
  }

  useEffect(() => {
    setLoading(true);
    fetchAddressCategories(1, "", fetchCategoriesSuccessCallback, fetchCategoriesFinallyCallback)
  }, [])

  if (loading) {
    return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <AdasLoading/>
    </Grid>
  }


  return (

    <Fragment>


      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" className={"paper"} open={open}
              fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.modalPadding}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"


          >

            <IconButton aria-label="delete" onClick={handleBack}>
              <ArrowBackIcon/>
            </IconButton>

            <Typography variant="h3" id="transition-modal-title">Categories</Typography>

            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon/>
            </IconButton>

          </Grid>
        </DialogTitle>

        <DialogContent dividers className={classes.dialogContentPadding}>
          <List component="nav" aria-label="Choose a category">
            {displayedCategories.children.map(category =>
              <ListItem key={category.id}
                        button
                        className={classes.categoryListItem}
                        onClick={() => {

                          if (category.children.length > 0) {
                            setDisplayedCategories(category);
                          } else {
                            setChosenCategory(category);
                            handleClose();
                          }
                        }}>
                <ListItemText primary={category.title}/>
                {category.children.length > 0 ? (
                  <IconButton aria-label="See children categorie" className={classes.paddingZero}>
                    <ChevronRightIcon/>
                  </IconButton>
                ) : null}
              </ListItem>
            )}
          </List>
        </DialogContent>

        <DialogActions className={classes.modalPadding}>
          <Button autoFocus onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        label="Category"
        margin="normal"
        name="category"
        value={chosenCategory.title}
        variant="outlined"
        InputLabelProps={{shrink: true}}
        onClick={handleOpen}
        className={classes.cursorPointer}
        {...customInputProps}
      />


    </Fragment>

  );
}

export default AddressCategoryChooserInput;
