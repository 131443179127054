const loadingActionType = {
  PAGE_LOADING: 'PAGE_LOADING',
  SUB_PAGE_LOADING: 'SUB_PAGE_LOADING',
}

const pageLoading = (loadingState) => {
  return (dispatch) => {
    dispatch({
      type: loadingActionType.PAGE_LOADING,
      payload: loadingState,
    })
  }
}

const subPageLoading = (loadingState) => {
  return (dispatch) => {
    dispatch({
      type: loadingActionType.SUB_PAGE_LOADING,
      payload: loadingState,
    })
  }
}

export {loadingActionType, pageLoading, subPageLoading} ;
