import {addressActionType} from "../actions/addressActions";

const initialState = {
  "lastPage": 0,
  "countItemsOnPage": 2,
  "current": 1,
  "next": null,
  "previous": null,
  "results": []
}


const addressReducer = (state = initialState, action) => {
  switch (action.type) {

    // GET
    case addressActionType.FETCH_ADDRESSES_SUCCESS:
      return {
        firstLoading: true,
        ...action.payload.response,
      }
    case addressActionType.FETCH_ADDRESS_SUCCESS:
      return {
        ...state
      }

    case addressActionType.FETCH_ADDRESSES_EMPTY:
      return initialState;

    case addressActionType.FETCH_ADDRESS_FAILED:
      return {
        ...state //copying the original state
      }

    // POST
    case addressActionType.POST_ADDRESS_SUCCESS:
      return {
        ...state, //copying the original state
        results: {
          ...state.results, //copying the original state of results
          ...action.payload.response //new address to add
        },
      }
    case addressActionType.POST_ADDRESS_FAILED:
      return {
        ...state
      }

    // DELETE
    case addressActionType.DELETE_ADDRESS_SUCCESS:
      return {
        ...state, //copying the original state
        results: state.results.filter((item) => item.id !== action.payload.id)
      }
    case addressActionType.DELETE_ADDRESS_FAILED:
      return {
        ...state
      }

    default:
      return state;
  }
}

export default addressReducer;
