import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useParams} from "react-router";
import UnprotectedPage from "../../components/UnprotectedPage";
import axiosInterceptor from "../../utils/axiosInterceptor";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ConfirmAccountView = () => {
  let {token} = useParams();
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgType, setMsgType] = useState("");


  useEffect(() => {

    const apiUrl = 'users/registration/verify-email/';
    let data = {};
    data.key = token;

    axiosInterceptor.post(apiUrl, data)
      .then(response => {
        if (response.status === 200) {
          let msg = 'Your account is confirmed. Please login by cliking the button bellow.';

          setMsg(msg);
          setMsgType("success");
          setLoaded(true);
        }
      })
      .catch(error => {
        if (error.response.status === 400 || error.response.status === 404) {
          let msg = "The verification url expired or is not valid.";

          setMsg(msg);
          setMsgType("error");
          setLoaded(true);

        }

        if (error.response.status === 500) {
          let msg = "An unexpected error occured.";

          setMsg(msg);
          setMsgType("error");
          setLoaded(true);
        }
      });
  }, []);

  return (
    <UnprotectedPage
      className={classes.root}
      title="Confirm your account"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            Account confirmation
          </Typography>

          <React.Fragment>
            {
              !loaded ? (

                  <div align="center" style={{padding: 20}}><CircularProgress/></div>
                ) :
                <React.Fragment>
                  {
                    msg !== "" ? (
                        <Box mb={3} mt={3}>

                          <Alert severity={msgType}>
                            {msg}
                          </Alert>

                          {
                            msgType === "success" ? (

                              <Box mb={3} mt={5}>
                                <RouterLink to="/login/">
                                  <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                  >
                                    Log in now
                                  </Button>
                                </RouterLink>
                              </Box>
                            ) : ""
                          }
                        </Box>
                      ) :
                      ""
                  }
                </React.Fragment>
            }
          </React.Fragment>

        </Container>
      </Box>
    </UnprotectedPage>
  );
};

export default ConfirmAccountView;
