import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import {User as UserIcon} from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
  avatar: {
    width: 60,
    height: 60
  },
}));

const TopBar = (props) => {

  const navigate = useNavigate();

  const classes = useStyles();

  const {className,onMobileNavOpen,...rest} = props;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo/>
        </RouterLink>
        <Box flexGrow={1}/>

        <Hidden mdDown>
          {/*}
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon/>
            </Badge>
          </IconButton>
          */}

          <IconButton color="inherit" onClick={() => {
            navigate('/account');
          }}>
            <UserIcon/>
          </IconButton>

          <IconButton color="inherit" onClick={() => {
            navigate('/logout');
          }}>
            <InputIcon/>
          </IconButton>
        </Hidden>

        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon/>
          </IconButton>
        </Hidden>

      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};


export default TopBar;
