import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  User as UserIcon,
} from 'react-feather';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import InputIcon from "@material-ui/icons/Input";
import NavItem from './NavItem';
import {connect} from "react-redux";

import {useNavigate} from "react-router";


/*
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
*/

const items = [
  {
    href: '/add/address',
    icon: AddCircleOutlineOutlinedIcon,
    title: 'Add a new address'
  },
  {
    href: '/addresses',
    icon: PersonPinCircleOutlinedIcon,
    title: 'My addresses'
  },
  {
    href: '/search',
    icon: SearchIcon,
    title: 'Search an address'
  },
  {
    href: '/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'Account settings'
  },
  {
    href: '/logout',
    icon: InputIcon,
    title: 'Log out'
  },
];


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  bgGradientRound: {
    background: "linear-gradient(135deg, rgba(221,251,251,1) 0%, rgba(250,237,234,1) 100%)",
    borderRadius: "5px",
  }
}));

// { onMobileClose, openMobile }
const NavBar = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const {openMobile, onMobileClose, user, } = props;


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.profile_img.img200x200}
          to="/account"
        />
        {
          user.role
        }
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.first_name + " " + user.last_name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {
            user.email
          }
        </Typography>
      </Box>
      <Divider/>
      <Box p={2}>
        <List>

          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}



        </List>
      </Box>
      <Box flexGrow={1}/>
      <Box
        p={2}
        m={2}
        className={classes.bgGradientRound}
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more addresses?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access unlimitted addresses
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="#"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{paper: classes.mobileDrawer}}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{paper: classes.desktopDrawer}}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {

  },
  openMobile: false
};

const mapStateToProps = (state) => {
  return {
    authState: state.authReducer,
    user: state.userReducer,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
