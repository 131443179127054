import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {authActionLogout} from "../../redux/actions/authActions";
import AdasLoading from "../../components/AdasLoading";
import {useNavigate} from "react-router-dom";



const LogoutView = (props) => {

  const {authLogoutDispatch} = props;
  const navigate = useNavigate();

  useEffect(()=>{
    authLogoutDispatch();
    navigate('/login');
  },[])

  return (
      <AdasLoading/>
  );
};

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authLogoutDispatch : () =>{
      dispatch(authActionLogout())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);
