import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import {putProfilePicMe} from "../../../redux/actions/userActions";
import {connect} from "react-redux";
import {Formik} from "formik";
import Grid from "@material-ui/core/Grid"


const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = (props) => {
  const classes = useStyles();

  const {loadingState, userState} = props;


  return (
    <Card
      className={clsx(classes.root, props.className)}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={userState.profile_img.img200x200}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {userState.first_name + " " + userState.last_name}
          </Typography>
          { /* }
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${user.city} ${user.country}`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
          { */}
        </Box>
      </CardContent>
      <Divider/>
      <CardActions>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >

          <Formik
            initialValues={{}}
            onSubmit={(data, actions) => {
              console.log(data)
              props.dispatchPutProfilePicMe(data);
              /*
              data.append("photo1", values.photo1);
              return fetch(baseUrl, {
                method: "post",
                headers: new Headers({
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                }),
                body: data,
              })
                .then((response) => response.json())
                .catch((error) => console.log(error));
            */
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType={"multipart/form-data"}
              >
                <input
                  accept="image/*"
                  className={"d-none"}
                  id="contained-button-file"
                  name="profile_img"
                  type="file"
                  onChange={(event) => {
                    setFieldValue("profile_img", event.currentTarget.files[0]);
                    handleSubmit();
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    disabled={loadingState.sub_page_loading}
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Upload picture
                  </Button>
                </label>
              </form>
            )}
          </Formik>

        </Grid>


      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    userState: state.userReducer,
    loadingState: state.loadingReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPutProfilePicMe: (formData) => {
      dispatch(putProfilePicMe(formData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
