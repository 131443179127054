import {loadingActionType} from "../actions/loadingActions"

const initialState = {
  page_loading: true, // Loading of all app in the begining
  sub_page_loading: false, // Loading of partially components in the page, when fetching api or something
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case loadingActionType.PAGE_LOADING:
      return {
        ...state,
        page_loading: action.payload
      }

    case loadingActionType.SUB_PAGE_LOADING:
      return {
        ...state,
        sub_page_loading: action.payload
      }
    default:
      return state;
  }
}

export default loadingReducer;
