import React, {useEffect, useState} from 'react';
import {
  Box, List, ListItem, ListItemText,
  makeStyles, Hidden, Avatar, Link, Fab, BottomNavigation, Paper
} from '@material-ui/core';
import applemaps from '../../asstes/icons/apple-map.png'
import googlemapsicon from '../../asstes/icons/google-maps.png'

import Page from 'src/components/Page';
import Grid from "@material-ui/core/Grid";

import {GOOGLE_API_KEY} from "../../utils/globals";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import CustomAutoSuggest from "../../components/CustomAutoSuggest";
import Typography from "@material-ui/core/Typography";

// Icons
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ShareIcon from '@material-ui/icons/Share';
import NavigationIcon from '@material-ui/icons/Navigation';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import MapIcon from '@material-ui/icons/Map';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {useLocation} from "react-router";
import Alert from "@material-ui/lab/Alert";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import axiosInterceptor from "../../utils/axiosInterceptor";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark, // for gradient
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
  searchGrid: {
    backgroundColor: "white",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(7),
    order: 2,
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 64px)',
      order: 1,
    },

  },
  mapGrid: {
    order: 1,
    minHeight: "300px",
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginTop: "0",
    },
  },

  searchCurrentSuggestionPrimary1: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  searchCurrentSuggestionSecondary1: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: theme.palette.secondary.main
  },
  searchCurrentSuggestionPrimary2: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  searchCurrentSuggestionSecondary2: {
    fontSize: "0.9rem",
  },


  /* Navigation map : Apple maps & Google maps */

  navigationMapGrid: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: "start",
    }
  },

  navigationMapTitle: {
    fontSize: "0.7rem",
    textDecoration: 'none',
    color: "black",
    marginTop: 4
  },

  navigationMapLink: {
    '&:hover': {
      '& $navigationMapImage': {
        boxShadow: "0px 0px 20px -3px rgba(0,0,0,0.55)",
      }
    },
  },


  navigationMapImage: {
    borderRadius: '10px',
    padding: "8px",
    height: "50px",
    width: "50px",
    border: "1px solid #aaaaaa42",
    transition: ".5s, color .5s",
  },

  navigationMapAppleImage: {
    border: "none",
    padding: 0,
  },


}));


const SearchView = () => {
  // url.com?da=XX-XXX-XXX
  // da = digital address
  const url_digital_address = new URLSearchParams(useLocation().search).get("da")
  const classes = useStyles();

  const mapStyles = {
    height: "100%",
    width: "100%",
  };
  // Navigation section and
  const [buttonShow, setButtonShow] = useState(true);
  const [sectionShow, setSectionShow] = useState(false);

  const handelSection = () => {
    setButtonShow(false);
    setSectionShow(true);
  }
  const handelButton = () => {
    setButtonShow(true);
    setSectionShow(false);
  }


  const [currentMapPosition, setCurrentMapPosition] = useState({lat: 0, lng: 0});
  const [currentMapZoom, setCurrentMapZoom] = useState(13);

  const [addressNotFound, setAddressNotFound] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [currentSuggestion, setCurrentSuggestions] = useState({
    "address": "---",
    "title": "---",
    "description": "---",
    "digital_address": "---",
    "country_name": "---",
    "country_code": "---",
    "geo_lat": "0",
    "geo_lon": "0"
  });

  const [currentSharedUrl, setCurrentSharedUrl] = useState(window.location.href);
  // Hnadling The Navegation Section
  let googleGeoUrl = `https://www.google.com/maps/search/${currentSuggestion.geo_lat},${currentSuggestion.geo_lon}/`
  let appleGeoUrl = `http://maps.apple.com/maps?q=${currentSuggestion.geo_lat},${currentSuggestion.geo_lon}`

  const NavigationMapIcons = () => {
    return <Grid container className={classes.navigationMapGrid}>
      <Box mr={1} mt={1}>
        <Link
          href={appleGeoUrl} target="_blank" variant="body2"
          underline={"none"}
          className={classes.navigationMapLink}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar className={[classes.navigationMapImage, classes.navigationMapAppleImage]} src={applemaps}/>
            <Typography variant="p" className={classes.navigationMapTitle}>
              Apple maps
            </Typography>
          </Box>
        </Link>
      </Box>
      <Box mr={1} mt={1}>
        <Link
          href={googleGeoUrl}
          target="_blank"
          variant="body2"
          underline={"none"}
          className={classes.navigationMapLink}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar className={classes.navigationMapImage} src={googlemapsicon}/>
            <Typography variant="p"
                        className={classes.navigationMapTitle}>
              Google maps
            </Typography>
          </Box>
        </Link>
      </Box>
    </Grid>
  }

  // End Hnadling The Navegation Section 
  useEffect(() => {

    if (url_digital_address !== undefined && url_digital_address !== null) {
      axiosInterceptor
        .get(`addresses/search/${url_digital_address}/`)
        .then(res => {
          setCurrentSuggestions(res.data);
        })
        .catch(err => {
          if (err.response.status === 404) {
            setAddressNotFound(true);
          }
        })
    } else {
      setCurrentMapPosition({
        lat: 0,
        lng: 0,
      })
      setCurrentMapZoom(1);
    }


  }, [])

  useEffect(() => {
    setCurrentMapPosition({
      lat: parseFloat(currentSuggestion.geo_lat),
      lng: parseFloat(currentSuggestion.geo_lon),
    });

    if (currentSuggestion.geo_lat !== "0" && currentSuggestion.geo_lon !== "0") {
      setCurrentMapZoom(13);
      setCurrentSharedUrl(`${window.location.href}?da=${currentSuggestion.digital_address}`)
    } else {
      setCurrentMapZoom(1);
    }

  }, [currentSuggestion])


  const onSearchResultClick = (addressId) => {
    setCurrentSuggestions(suggestions.find(x => x.id === addressId));
  }

  const onChange = (event, {newValue}) => {
    setValue(newValue);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.digital_address;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <Grid container onClick={() => {
      onSearchResultClick(suggestion.id);
    }}>
      <Grid item xs={12}>
        <Typography color={"red"} variant={"body1"}>
          {suggestion.digital_address}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color={"red"} variant={"body2"}>
          {suggestion.address}
        </Typography>
      </Grid>
    </Grid>
  );

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = value => {
    // Replace setSearchResults
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return setSuggestions([]);
    }

    let apiUrl = `addresses/search/?search=${inputValue}`;


    axiosInterceptor
      .get(apiUrl)
      .then(res => {
        setAddressNotFound(false);
        setSuggestions(res.data.results);
        return [];
      })
      .catch(err => {
        if (err.response.status === 404) {
          // Print msg not found
          setSuggestions([]);
          return [];
        }

      })

    return [];
  };


  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({value}) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: 'Type your keywords...',
    value,
    onChange: onChange
  };

  return (
    <Page
      className={classes.root}
      title="Search"
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={3} className={classes.searchGrid}>
          <Box pb={4} pr={4} pl={4}>

            {addressNotFound ? (
              <Box mb={2}>
                <Alert severity="warning">This digital address was not found. <br/> Please search another.</Alert>
              </Box>
            ) : ""}

            <CustomAutoSuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}

            />

            <List>
              <ListItem>
                <ListItemIcon
                  alignItems="flex-start"
                >
                  <MyLocationTwoToneIcon fontSize={"large"} color={"primary"}/>
                </ListItemIcon>

                <ListItemText
                  classes={{
                    primary: classes.searchCurrentSuggestionPrimary1,
                    secondary: classes.searchCurrentSuggestionSecondary1,
                  }}
                  color={"primary"}
                  primary="Digital address"
                  secondary={currentSuggestion.digital_address}
                />

              </ListItem>

              <ListItem>
                <ListItemIcon
                  alignItems="flex-start"
                >
                  <MapIcon fontSize={"large"} color={"primary"}/>
                </ListItemIcon>

                <ListItemText
                  classes={{
                    primary: classes.searchCurrentSuggestionPrimary2,
                    secondary: classes.searchCurrentSuggestionSecondary2,
                  }}
                  color={"primary"}
                  primary="Address"
                  secondary={currentSuggestion.address}
                />

              </ListItem>

              <ListItem>
                <ListItemIcon
                  alignItems="flex-start"
                >
                  <LocationOnIcon fontSize={"large"} color={"primary"}/>
                </ListItemIcon>

                <ListItemText
                  classes={{
                    primary: classes.searchCurrentSuggestionPrimary2,
                    secondary: classes.searchCurrentSuggestionSecondary2,
                  }}
                  color={"primary"}
                  primary="Latitude,Longitude"
                  secondary={currentSuggestion.geo_lat + "," + currentSuggestion.geo_lon}
                />

              </ListItem>

              <ListItem>

                <ListItemIcon
                  alignItems="flex-start"
                >
                  <ShareIcon fontSize={"large"} color={"primary"}/>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.searchCurrentSuggestionPrimary2,
                    secondary: classes.searchCurrentSuggestionSecondary2,
                  }}
                  color={"primary"}
                  primary="Share this location"
                  secondary={<Grid container>
                    <Box mr={1} mt={1}><FacebookShareButton url={currentSharedUrl}
                                                            children={<FacebookIcon size={32} round={true}/>}/></Box>
                    <Box mr={1} mt={1}><TwitterShareButton url={currentSharedUrl}
                                                           children={<TwitterIcon size={32} round={true}/>}/></Box>
                    <Box mr={1} mt={1}><WhatsappShareButton url={currentSharedUrl}
                                                            children={<WhatsappIcon size={32} round={true}/>}/></Box>
                  </Grid>}
                />
              </ListItem>
              {
                currentSuggestion.geo_lon === "0" && currentSuggestion.geo_lon === "0"
                  ? null
                  : <Hidden smDown>
                    <ListItem>

                      <ListItemIcon
                        alignItems="flex-start"
                      >
                        <NavigationIcon fontSize={"large"} color={"primary"}/>
                      </ListItemIcon>

                      <ListItemText
                        classes={{
                          primary: classes.searchCurrentSuggestionPrimary2,
                          secondary: classes.searchCurrentSuggestionSecondary2,
                        }}
                        color={"primary"}
                        primary="Navigation"
                        secondary={<NavigationMapIcons/>}
                      />
                    </ListItem>
                  </Hidden>
              }


            </List>

          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} className={classes.mapGrid}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={currentMapZoom}
            center={currentMapPosition}>
            <Marker
              position={currentMapPosition}
            />

          </GoogleMap>
        </Grid>
      </Grid>
      {
        currentSuggestion.geo_lon === "0" && currentSuggestion.geo_lon === "0"
          ? null
          : <Hidden mdUp>
            <Paper style={{backgroundColor: 'transparent', position: 'fixed', bottom: 8, left: 0, right: 0, border:"none",boxShadow:"none"}}
                   elevation={3}>
              <BottomNavigation
                style={{backgroundColor: 'transparent'}}
                showLabels
              >
                {
                  buttonShow ? <Fab
                    style={{backgroundColor: '#2C83F6', width: '200px', color: 'white'}} variant="extended"
                    onClick={() => handelSection()}
                  >

                    Navigate
                  </Fab> : null}
              </BottomNavigation>
              {/* Nvigation Section */}
              {
                sectionShow ?
                  <Box style={{width: '100%', display: 'flex', flexDirection: "column", backgroundColor: 'white'}}>
                    <Box style={{
                      height: '35px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#F4F5FF',
                      color: 'black'
                    }}>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => handelButton()}
                      >
                        <Typography variant="p" style={{
                          display: 'flex',
                          textAlign: 'center',
                          alignItems: 'center',
                          height: '35px',
                          fontSize: '16px',
                          textDecoration: 'none',
                          color: 'black',
                          marginLeft: '2rem'
                        }}>
                          <NavigateBeforeIcon sx={{mr: 1}}/>
                          Navigate
                        </Typography>
                      </Link>
                    </Box>
                    {/* the icons container */}
                    <Box my={1}>
                      <NavigationMapIcons/>
                    </Box>
                  </Box> : null
              }

            </Paper>
          </Hidden>
      }


    </Page>
  );
};

export default SearchView;
