import React from 'react';
import {Navigate} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import ConfirmAccountView from "src/views/auth/ConfirmAccountView";
import ForgotPasswordRequest from "./views/auth/ForgotPasswordRequest";
import ForgotPasswordConfirm from "./views/auth/ForgotPasswordConfirm";
import ProductCreateView from "./views/product/ProductCreateView";
import AddressUpdateView from "./views/product/AddressUpdateView";
import SearchView from "./views/search/SearchView";
import LogoutView from "./views/auth/LogoutView";
import ForbiddenView from "./views/errors/ForbiddenView";
import RegisterBusinessView from "./views/auth/RegisterBusinessView";

const routes = [
  {
    path: '',
    element: <DashboardLayout/>,
    children: [

      /* Normal user pages */
      {path: 'addresses',exact:true, element: <ProductListView />},
      {path: 'account', element: <AccountView/>},
      {path: 'settings', element: <SettingsView/>},
      {path: '/', element: <Navigate to="/account"/>},


      {path: '*', element: <Navigate to="/404"/>},
    ]
  },
  {
    path: '/',
    element: <MainLayout/>,
    children: [
      {path: 'search', element: <SearchView />},
      {path: 'add/address',exact:true, element: <ProductCreateView />},
      {path: 'addresses/:id/edit', element: <AddressUpdateView />},

      {path: 'login', element: <LoginView/>},
      {path: 'logout', element: <LogoutView/>},
      {path: 'register/business/', element: <RegisterBusinessView/>},
      {path: 'register', element: <RegisterView/>},
      {path: 'account-confirm-email/:token/', element: <ConfirmAccountView/>},
      {path: 'forgot-password/', element: <ForgotPasswordRequest/>},
      {path: 'forgot-password/confirm/:uid-:token1-:token2/', element: <ForgotPasswordConfirm/>},

      {path: '404', element: <NotFoundView/>},
      {path: '403', element: <ForbiddenView/>},
      {path: '/', element: <Navigate to="/addresses"/>},
      {path: '*', element: <Navigate to="/404"/>}
    ]
  }
];

export default routes;
