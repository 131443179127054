import {pageLoading} from "./loadingActions";
import axiosInterceptor from "../../utils/axiosInterceptor";


const addressTitlesAutocompleteActionType = {
  // FETCH MULTIPLE
  FETCH_ADDRESS_TITLES_AUTOCOMPLETE_STARTED: 'FETCH_ADDRESS_TITLES_AUTOCOMPLETE_STARTED',
  FETCH_ADDRESS_TITLES_AUTOCOMPLETE_SUCCESS: 'FETCH_ADDRESS_TITLES_AUTOCOMPLETE_SUCCESS',
  FETCH_ADDRESS_TITLES_AUTOCOMPLETE_EMPTY: 'FETCH_ADDRESS_TITLES_AUTOCOMPLETE_EMPTY',
  FETCH_ADDRESS_TITLES_AUTOCOMPLETE_FAILED: 'FETCH_ADDRESS_TITLES_AUTOCOMPLETE_FAILED',
}


const AddressTitlesAutocompleteFetchSuccess = (response) => {
  return (dispatch) => {
    dispatch({
      type: addressTitlesAutocompleteActionType.FETCH_ADDRESS_TITLES_AUTOCOMPLETE_SUCCESS,
      payload: {
        response
      },
    })
  }
}

const AddressTitlesAutocompleteFetchEmpty = () => {
  return (dispatch) => {
    dispatch({
      type: addressTitlesAutocompleteActionType.FETCH_ADDRESS_TITLES_AUTOCOMPLETE_EMPTY,
      payload: "",
    })
  }
}

const AddressTitlesAutocompleteFetchFailed = () => {
  return (dispatch) => {
    dispatch({
      type: addressTitlesAutocompleteActionType.FETCH_ADDRESS_TITLES_AUTOCOMPLETE_FAILED,
      payload: "",
    })
  }
}


const fetchAddressTitlesAutocompleteAction = () => {

  return dispatch => {
    dispatch(pageLoading(true));


    let apiUrl = `addresses/address-title-autocomplete/`

    axiosInterceptor
      .get(apiUrl)
      .then(res => {
        dispatch(AddressTitlesAutocompleteFetchSuccess(res.data));
      })
      .catch(err => {
        if(err.status === 404){
          dispatch(AddressTitlesAutocompleteFetchEmpty());
        }
        dispatch(AddressTitlesAutocompleteFetchFailed(err.message));
      }).finally(() => {
      dispatch(pageLoading(false));
    });

  };

};


export {
  addressTitlesAutocompleteActionType,
  fetchAddressTitlesAutocompleteAction,
};
