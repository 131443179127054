import React from 'react';
import {Navigate} from 'react-router-dom';
import Page from 'src/components/Page';
import {connect} from "react-redux";


const UnprotectedPage = (props) => {

  const {authState} = props;

  if(authState.isLoggedIn){
    return <Navigate to="/" />
  }

  return (
    <Page
      className={props.className}
      title={props.title}
    >
      {props.children}
    </Page>
  );

};

const mapStateToProps = (state) => {
  return {
    authState: state.authReducer,
    loadingState: state.loadingReducer,
  }
}


export default connect(mapStateToProps)(UnprotectedPage);
