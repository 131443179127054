import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const AdasLoading = (props) => {

  return (
    <Grid container
          spacing={0}
          alignItems="center"
          justify="center"
          style={{ minHeight: "calc(100vh - 64px)" }}>
      <CircularProgress color="primary" />
    </Grid>
  );
};

export default AdasLoading;
