import React from 'react';
import {Navigate} from 'react-router-dom';
import Page from 'src/components/Page';
import {connect} from "react-redux";


const ProtectedPage = (props) => {
  //const classes = useStyles();

  const {authState, loadingState} = props;

  if(!authState.isLoggedIn && !loadingState.page_loading){
    return <Navigate to="/login" />
  }

  return (
    <Page
      className={props.className}
      title={props.title}
    >
      {props.children}
    </Page>
  );

};

const mapStateToProps = (state) => {
  return {
    authState: state.authReducer,
    loadingState: state.loadingReducer,
  }
}


export default connect(mapStateToProps)(ProtectedPage);
