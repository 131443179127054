import {addressTitlesAutocompleteActionType} from "../actions/addressTitleAutocompleteActions";

const initialState = {
  "count": 0,
  "next": null,
  "previous": null,
  "results": []
}


const addressTitlesAutocompleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case addressTitlesAutocompleteActionType.FETCH_ADDRESS_TITLES_AUTOCOMPLETE_SUCCESS:
      return {
        firstLoading: true,
        ...action.payload.response,
      }

    case addressTitlesAutocompleteActionType.FETCH_ADDRESS_TITLES_AUTOCOMPLETE_EMPTY:
      return initialState;

    case addressTitlesAutocompleteActionType.FETCH_ADDRESS_TITLES_AUTOCOMPLETE_FAILED:
      return {
        ...state
      }
    default:
      return state;
  }
}

export default addressTitlesAutocompleteReducer;
