import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardContent,
  Grid,
  TextField,
  makeStyles, Typography, Backdrop, CircularProgress
} from '@material-ui/core';
import {Formik} from 'formik';
import {connect} from "react-redux";
import * as Yup from "yup";
import MapContainer from "../../../components/MapContainer";
import {fetchAddressAction, patchAddressAction} from "../../../redux/actions/addressActions";
import Alert from "@material-ui/lab/Alert";
import {useNavigate} from "react-router";
import LocationSearchInputComponent from "../../../components/LocationSearchInputComponent";
import MyLocationTwoToneIcon from "@material-ui/icons/MyLocationTwoTone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {apiGenerateDigitalAddress} from "../../../redux/api/addresses";
import AdasLoading from "../../../components/AdasLoading";
import AddressCategoryChooserInput from "../../../components/categories/AddressCategoryChooserInput";

const useStyles = makeStyles((theme) => ({
  root: {},
  FormError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  },
  buttonTertiary: {
    backgroundColor: theme.palette.tertiary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.tertiary.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.tertiary.light
    }
  },


  greenBtn: {
    backgroundColor: theme.palette.green.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.green.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.green.light
    }
  },

  addressBox:{
    padding: "18.5px 14px",
    border:"1px solid #0000003b",
    borderRadius: "4px",
    marginTop:"8px",
    marginBottom:"8px",
  },
  addressBoxTitle:{
    color: "#939393",
  },
  addressBoxContent:{
    fontSize:15,
    color:"#001260",
  },
  digitalAddressBoxContent: {
    fontSize:40,
    fontWeight:"bolder",
  },
  pageTitle:{
    fontWeight:"bolder",
  },

  searchGrid: {
    backgroundColor: "white",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(7),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    order: 2,
    [theme.breakpoints.up('md')]: {
      //height: 'calc(100vh - 64px)',
      height: 'calc(100vh)',
      order: 1,
    },

  },
  searchGridItem: {
    padding: "0 !important",

  },
  mapGrid: {
    order: 1,
    minHeight: "300px",
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginTop: "0",
    },
  },


  searchCurrentSuggestionPrimary1: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  searchCurrentSuggestionSecondary1: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: theme.palette.secondary.main
  },
  searchCurrentSuggestionPrimary2: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  searchCurrentSuggestionSecondary2: {
    fontSize: "0.9rem",
  }

}));

const AddressUpdateForm = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const formikRef = useRef(null);
  const [firstPageLoad, setFirstPageLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const { addressTitlesAutocompleteState, loadingState, dispatchFetchAddress, dispatchPatchAddress} = props;

  const [countryCode, setCountryCode] = useState("");
  const [address404, setAddress404] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [address, setAddress] = useState({
    "id": "",
    "title": "",
    "category": null,
    "description": "",
    "plus_code": "",
    "digital_address": "",
    "address": "",
    "country_name": "",
    "country_code": "",
    "geo_lat": "---",
    "geo_lon": "---"
  });

  const generateDigitalAddressSuccessCallback = () => {
    setLoading(false);
  }

  const generateNewDigitalAddress = (lat, lon) => {

    setLoading(true);

    apiGenerateDigitalAddress(
      lat,
      lon,
      address,
      setAddress,
      setCountryCode,
      generateDigitalAddressSuccessCallback
    )
  }


  useEffect(() => {

    setAddressId(props.id);
    dispatchFetchAddress(props.id, setAddress, setAddress404, (address)=>{

      setCurrentMapPosition({
        lat: parseFloat(address.geo_lat),
        lng: parseFloat(address.geo_lon)
      })
      setCurrentMapZoom(13);
      setFirstPageLoad(false);
      setPageLoading(false);

    });

  }, [])


  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [currentMapPosition, setCurrentMapPosition] = useState({lat: 0, lng: 0});
  const [currentMapZoom, setCurrentMapZoom] = useState(13);

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentMapPosition({lat, lng})
  };



  useEffect(() => {
    if(!firstPageLoad) {
      generateNewDigitalAddress(currentMapPosition.lat, currentMapPosition.lng)
    }

  }, [currentMapPosition])


  const successCurrentPositionCallback = position => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }

    setCurrentMapPosition(currentPosition);
    setCurrentMapZoom(13);

  };

  const errorCurrentPositionCallback = err => {
    console.log(err)
  };

  const onCurrentPositionClick = (e) => {
    navigator.geolocation.getCurrentPosition(successCurrentPositionCallback, errorCurrentPositionCallback, {timeout: 10000});
  }

  if (address404) {
    navigate('/404');
  }

  if(pageLoading){
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (

    <React.Fragment>

        <Grid
          style={firstPageLoad ? { display: 'initial'} : {display: 'none'}}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >

          <AdasLoading/>

        </Grid>

        <div style={firstPageLoad ? { display: 'none'} :  { display: 'initial'}}>
            <Formik
              innerRef={formikRef}
              initialValues={{
                title: address.title,
                category: address.category,
                description: address.description,
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string().min(1).max(200).required('Address title is required'),
                category: Yup.mixed().required('Address category is required').nullable(false, 'Address category is required'),
                description: Yup.string().min(1),
              })}
              onSubmit={(data, actions) => {
                setShowWarning(false);
                actions.setSubmitting(true);
                setAddress({
                  ...address,
                  title: data.title,
                  category:data.category,
                  description: data.description,
                })

                let form_data = {
                  title: data.title,
                  category:data.category,
                  description: data.description,
                  address: address.address,
                  digital_address: address.digital_address,
                  plus_code: address.plus_code,
                  country_name: address.country_name,
                  country_code: address.country_code,
                  geo_lat: address.geo_lat,
                  geo_lon: address.geo_lon,
                  is_temporary: address.is_temporary
                }

                dispatchPatchAddress(addressId, form_data, () => {
                  setShowSuccessMsg(true);
                });
                actions.setSubmitting(false);


              }}
            >
              {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                <form
                  encType={"multipart/form-data"}
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={3} className={classes.searchGrid}>




                      <CardContent>
                        <Grid
                          container
                        >

                          <Box mb={1}>
                            <Typography
                              className={classes.pageTitle}
                              color="secondary"
                              variant="h2"
                            >
                              Update your address
                            </Typography>

                            <Typography
                              color="textSecondary"
                              variant="body2"
                            >
                              You can drag and drop the marker manually.
                            </Typography>
                          </Box>



                          {showSuccessMsg ? (
                            <Grid item xs={12}>
                              <Box mt={3}>
                                <Alert severity="success">The address was updated successfully.</Alert>
                              </Box>
                            </Grid>) : (
                            <></>
                          )}

                          {showWarning ? (
                            <Grid item xs={12}>
                              <Box mt={3}>
                                <Alert severity="error">An unexpected error occured. Please drag and drop your marker to
                                  choose an address.</Alert>
                              </Box>
                            </Grid>) : (
                            <></>
                          )}

                          <Grid item
                                className={classes.searchGridItem}
                                xs={12}>
                            <LocationSearchInputComponent success_callback={setCurrentMapPosition}/>
                          </Grid>

                          <Grid
                            item
                            className={classes.searchGridItem}
                            xs={12}
                          >
                            <Box>
                              <Button
                                fullWidth
                                className={classes.greenBtn}
                                variant="contained"
                                onClick={onCurrentPositionClick}
                                startIcon={<MyLocationTwoToneIcon/>}
                              >
                                Use my current location
                              </Button>

                            </Box>
                          </Grid>



                          <Grid item
                                className={classes.searchGridItem}
                                xs={12}>

                            <Box mt={3} />

                            <Box className={classes.addressBox}>
                              <Typography
                                className={classes.addressBoxTitle}
                                color="textSecondary"
                                variant="h6"
                              >
                                Digital address
                              </Typography>

                              <Typography
                                className={classes.digitalAddressBoxContent}
                                color="secondary"
                                variant="h2"
                              >
                                {address.digital_address}
                              </Typography>
                            </Box>

                            <Box className={classes.addressBox}>

                              <Box>
                                <Typography
                                  className={classes.addressBoxTitle}
                                  color="textSecondary"
                                  variant="h6"
                                >
                                  Address
                                </Typography>

                                <Typography
                                  className={classes.addressBoxContent}
                                  color="textSecondary"
                                  variant="h6"
                                >
                                  {address.address}
                                </Typography>
                              </Box>

                              <Box>
                                <Typography
                                  className={classes.addressBoxTitle}
                                  color="textSecondary"
                                  variant="h6"
                                >
                                  Latitude,Longitude
                                </Typography>

                                <Typography
                                  className={classes.addressBoxContent}
                                  color="textSecondary"
                                  variant="h6"
                                >
                                  {address.geo_lat + "," + address.geo_lon}
                                </Typography>
                              </Box>

                            </Box>

                          </Grid>


                          <Grid
                            item
                            className={classes.searchGridItem}
                            xs={12}
                          >
                            <Autocomplete
                              freeSolo
                              autoSelect
                              value={values.title}
                              error={Boolean(touched.title && errors.title)}
                              helperText={touched.title && errors.title}
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                formikRef.current.setFieldValue("title", value)
                              }}
                              options={addressTitlesAutocompleteState.results.map((option) => option.title)}

                              renderInput={(params) => (
                                <TextField {...params}
                                           label="Address title"
                                           margin="normal"
                                           variant="outlined"
                                           placeholder="Eg. Home address, Work address, etc..."
                                           InputLabelProps={{shrink: true}}
                                />
                              )}
                            />

                            <p className={classes.FormError}>
                              {errors.title}
                            </p>

                          </Grid>

                          <Grid
                            item
                            className={classes.searchGridItem}
                            xs={12}
                          >
                            <AddressCategoryChooserInput
                              initialCategoryId={values.category}
                              setChosenCategoryId={(categoryId) => {
                                formikRef.current.setFieldValue("category", categoryId)
                              }}

                              customInputProps={{
                                onBlur:handleBlur,
                                onChange:handleChange,
                                error:Boolean(touched.category && errors.category)
                              }}

                            />

                            <p className={classes.FormError}>
                              {errors.category}
                            </p>


                          </Grid>

                          <Grid
                            item
                            className={classes.searchGridItem}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.description && errors.description)}
                              fullWidth
                              helperText={touched.description && errors.description}
                              label="Additional information"
                              margin="normal"
                              name="description"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.description}
                              variant="outlined"
                              placeholder={"Eg. House number, floor ect..."}
                              InputLabelProps={{shrink: true}}
                            />
                          </Grid>



                        </Grid>
                      </CardContent>


                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        mr={2}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={loadingState.sub_page_loading}
                          onClick={handleSubmit}
                        >
                          Update address
                        </Button>
                      </Box>

                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={9} className={classes.mapGrid}>
                      <MapContainer currentPosition={currentMapPosition}
                                    currentMapZoom={currentMapZoom}
                                    onMarkerDragEnd={(e) => onMarkerDragEnd(e)}
                      >

                      </MapContainer>
                    </Grid>
                  </Grid>


                </form>


              )}
            </Formik>
          </div>




    </React.Fragment>


  );
};

AddressUpdateForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    loadingState: state.loadingReducer,
    addressTitlesAutocompleteState: state.addressTitlesAutocompleteReducer,
    authState: state.authReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchFetchAddress: (id, setAddress, setAddress404, successCallback) => {
      dispatch(fetchAddressAction(id, setAddress, setAddress404, successCallback))
    },
    dispatchPatchAddress: (addressId, formData, success_cb) => {
      dispatch(patchAddressAction(addressId, formData, success_cb))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressUpdateForm);
