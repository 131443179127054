import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import {updatePasswordAction} from "../../../redux/actions/userActions";
import {connect} from "react-redux";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import {Formik} from "formik";

const useStyles = makeStyles(({
  root: {}
}));

const Password = (props,{className, ...rest}) => {
  const passwordFormik = useRef()

  const classes = useStyles();

  const {loadingState, updatePasswordDispatch} = props;

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);



  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider/>
        <CardContent>
          <Formik
            initialValues={{
              new_password1: '',
              new_password2: '',
            }}
            validationSchema={
              Yup.object().shape({
                new_password1: Yup.string().min(8).max(128).required('password is required'),
                new_password2: Yup.string().min(8).max(128).required('password confirmation is required').oneOf([Yup.ref('new_password1'), null], 'The two passwords must match')
              })
            }
            onSubmit={(data, actions) => {
              updatePasswordDispatch(data, setShowSuccessMsg, actions);
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (

              <form  ref={passwordFormik} onSubmit={handleSubmit}>

                {showSuccessMsg ? (
                  <Alert severity="success">Your password was updated successfully.</Alert>
                ) : ""}

                <TextField
                  error={Boolean(touched.new_password1 && errors.new_password1)}
                  fullWidth
                  helperText={touched.new_password1 && errors.new_password1}
                  label="Password"
                  margin="normal"
                  name="new_password1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password1}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.new_password2 && errors.new_password2)}
                  fullWidth
                  helperText={touched.new_password2 && errors.new_password2}
                  label="Repeat password"
                  margin="normal"
                  name="new_password2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password2}
                  variant="outlined"
                />


              </form>

            )}
          </Formik>
        </CardContent>
        <Divider/>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={loadingState.sub_page_loading}
            onClick={()=>{
              if(passwordFormik.current){
                passwordFormik.current.dispatchEvent(new Event('submit'));
              }
            }}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    loadingState: state.loadingReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePasswordDispatch: (formData, showSuccessMsg, formikActions) => {
      dispatch(updatePasswordAction(formData, showSuccessMsg, formikActions))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Password);
